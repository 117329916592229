export const addCommas = (number) => {
    // Convert number to string
    let numberStr = number.toString();
    // Split the number into integer and decimal parts (if any)
    let parts = numberStr.split('.');
    // Add commas to the integer part
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // Join the integer and decimal parts back together
    return parts.join('.');
}


export const calculateMinMaxValues = (projectDataList) => {

    const range = {
        modelCost: [0, -0],
        builtUpArea: [0, -0],
        frontLotSize: [0, -0],
        sqFt: [0, -0], // Assuming sqFt is similar to builtUpArea
        propClosingYear: [0, -0],
        totalDeposit: [0, -0]
    };

    projectDataList.forEach(projectData => {
        // Check propClosingYear at the project level
      
        if(projectData.propClosingYear>0 && range.propClosingYear[0]===0){
            range.propClosingYear[0]=projectData.propClosingYear;
        }
        
        range.propClosingYear[0] = Math.min(range.propClosingYear[0], projectData.propClosingYear);
        range.propClosingYear[1] = Math.max(range.propClosingYear[1], projectData.propClosingYear);

        projectData.projectModelData.forEach(modelData => {
            range.modelCost[0] = Math.min(range.modelCost[0], modelData.modelCost);
            range.modelCost[1] = Math.max(range.modelCost[1], modelData.modelCost);

            range.builtUpArea[0] = Math.min(range.builtUpArea[0], modelData.builtUpArea);
            range.builtUpArea[1] = Math.max(range.builtUpArea[1], modelData.builtUpArea);

            range.frontLotSize[0] = Math.min(range.frontLotSize[0], modelData.frontLotSize);
            range.frontLotSize[1] = Math.max(range.frontLotSize[1], modelData.frontLotSize);

            range.sqFt[0] = Math.min(range.sqFt[0], modelData.builtUpArea); // Assuming sqFt is similar to builtUpArea
            range.sqFt[1] = Math.max(range.sqFt[1], modelData.builtUpArea);

            range.totalDeposit[0] = Math.min(range.totalDeposit[0], modelData.totalDeposit);
            range.totalDeposit[1] = Math.max(range.totalDeposit[1], modelData.totalDeposit);
        });
    });

    return {
        modelCost: range.modelCost,
        builtUpArea: range.builtUpArea,
        frontLotSize: range.frontLotSize,
        sqFt: range.sqFt,
        propClosingYear: range.propClosingYear,
        totalDeposit: range.totalDeposit
    };
}

export const getPropertyList = (projectDataList) => {
    // Flatten the array of arrays and filter out unique property types using a Set
    const propertyTypes = projectDataList
        .flatMap(k => k.projectModelData.map(l => l.propertyType))
        .filter((value, index, self) => self.indexOf(value) === index);
    return propertyTypes;
};

export const getBasementList = (projectDataList) => {
    // Flatten the array of arrays and filter out unique property types using a Set
    const basementTypes = projectDataList
        .flatMap(k => k.projectModelData.map(l => l.basementType))
        .filter((value, index, self) => self.indexOf(value) === index);
    return basementTypes;
};

export const getStatusList = (projectDataList) => {
    // Flatten the array of arrays and filter out unique status types using a Set
    const statusTypes = projectDataList.map((i=>i.status))
        
        .filter((value, index, self) => self.indexOf(value) === index);
    return statusTypes;
};

export const filterApprovedProjects = (projectDataList, approvalStatus) => {
    return projectDataList
        .filter(project => project.approval === approvalStatus)
        .map(project => ({
            ...project,
            projectModelData: project.projectModelData.filter(modelData => modelData.approval === approvalStatus)
        }))
        .filter(project => project.projectModelData.length > 0);
};



