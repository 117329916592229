// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Style for the active tab */
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #343a40; /* Dark color */
    font-weight: bold;
    background-color: #e9ecef;
    border-color: #dee2e6 #dee2e6 #fff;
  }
  
  /* Style for inactive tabs */
  .nav-tabs .nav-link {
    color: #6c757d; /* Lighter color */
    font-weight: bold;
    border: 1px solid #dee2e6;
  }
  
  /* Style for tab container */
  .nav-tabs {
    border-bottom: 2px solid #dee2e6;
  }
  
  /* Style for the card */
  .property-card {
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
  }
  
  .property-card .card-body {
    padding: 20px;
  }
  
  .property-card .table thead th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
  
  .property-card .table tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  .property-card .table tbody td {
    vertical-align: middle;
  }
  
  .property-card .table {
    margin-bottom: 0;
  }
  
  .property-card h6 {
    font-size: 1.25rem;
    color: #343a40;
  }
  `, "",{"version":3,"sources":["webpack://./src/jsx/propertyApprovals.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;IACI,cAAc,EAAE,eAAe;IAC/B,iBAAiB;IACjB,yBAAyB;IACzB,kCAAkC;EACpC;;EAEA,4BAA4B;EAC5B;IACE,cAAc,EAAE,kBAAkB;IAClC,iBAAiB;IACjB,yBAAyB;EAC3B;;EAEA,4BAA4B;EAC5B;IACE,gCAAgC;EAClC;;EAEA,uBAAuB;EACvB;IACE,yBAAyB;IACzB,sBAAsB;IACtB,wCAAwC;IACxC,cAAc;EAChB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,cAAc;EAChB","sourcesContent":["/* Style for the active tab */\n.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {\n    color: #343a40; /* Dark color */\n    font-weight: bold;\n    background-color: #e9ecef;\n    border-color: #dee2e6 #dee2e6 #fff;\n  }\n  \n  /* Style for inactive tabs */\n  .nav-tabs .nav-link {\n    color: #6c757d; /* Lighter color */\n    font-weight: bold;\n    border: 1px solid #dee2e6;\n  }\n  \n  /* Style for tab container */\n  .nav-tabs {\n    border-bottom: 2px solid #dee2e6;\n  }\n  \n  /* Style for the card */\n  .property-card {\n    border: 1px solid #dee2e6;\n    border-radius: 0.25rem;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    margin: 20px 0;\n  }\n  \n  .property-card .card-body {\n    padding: 20px;\n  }\n  \n  .property-card .table thead th {\n    background-color: #f8f9fa;\n    font-weight: bold;\n  }\n  \n  .property-card .table tbody tr:hover {\n    background-color: #f1f1f1;\n  }\n  \n  .property-card .table tbody td {\n    vertical-align: middle;\n  }\n  \n  .property-card .table {\n    margin-bottom: 0;\n  }\n  \n  .property-card h6 {\n    font-size: 1.25rem;\n    color: #343a40;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
