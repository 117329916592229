
import React, { useState, useEffect } from "react";
import { useForm ,Controller} from 'react-hook-form';
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { getContextRoot } from "../../../context/Config";
import Select from "react-select";



//import { faL } from "@fortawesome/free-solid-svg-icons";
const PropertyShare = props => {
    const location = useLocation();


    const navigate = useNavigate();
    const [emailObj, setEmailObj] = useState({
        from: "",
        to: "",
        subject: "",
        projectId: "",
        content: ""
    });

    let [templates, setTemplates] = useState([{
        templateName: "Select template",
        salutation: "",
        subject: "",
        content: "",
        closing: ""
    }]);

    const [templateOptions, setTemplateOptions] = useState([]);

    const getTemplates = () => {
        axios.get(getContextRoot() + "/api/metadata/emailContent/all")
            .then((response) => {
                setTemplates(response.data);
                setTemplateOptions(response.data.map(item => ({ value: item.templateName, label: item.templateName })));
      
            })
            .catch((error) => {
                toast.error("Error fetching data", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                console.error("Error fetching data:", error);
            });
    };

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setEmailObj({ ...emailObj, [name]: value });
    };

    const handleSelectChange = (e) => {
        const value = e.value;
       
        templates.filter(template => template.templateName === value)
            .map(temp => (
                setEmailObj({
                    ...emailObj,
                    ["content"]: temp.salutation + ",\n\n" + temp.content + "\n\n <ALLLINKS>" + "\n\n" + temp.closing,
                    ["subject"]:temp.subject
                })
            ));
    };

    const { handleSubmit ,control} = useForm();

    const onSubmit = async (data) => {
        let agentEmail = JSON.parse(localStorage.getItem('userinfo')).email;
        //console.log(emailObj);
        emailObj.from = agentEmail;
        emailObj.projectId = location.state.propertyId.toString();
        try {
            const response = await axios.post(getContextRoot() + "/api/public/project-details/shareProject", emailObj);
            toast.success("Data sent to backend successfully", {
                position: toast.POSITION.TOP_RIGHT,
            });

            navigate("/property-list");

            // Optionally, you can handle the response from the server here
        } catch (error) {
            toast.error("Error submitting data", {
                position: toast.POSITION.TOP_RIGHT,
            });
            console.error("Error sending data to backend:", error);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        navigate("/property-list");
    }

    useEffect(() => {
        getTemplates();
    }, []);

    return (

        <>
            <div className="row property">
                <div className="col-12">
                    <div className="card" name="EmailTemplate">
                        <div className="card-header">
                            <h4 className="card-title">Share Property Details</h4>

                        </div>
                        <div className="card-body">

                            <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div >
                                        <label className="form-label">To:<span className="text-danger">*</span></label>
                                        <input
                                            type="email" multiple
                                            className="form-control"
                                            placeholder="Email"
                                            name="to"
                                            value={emailObj.to}
                                            onChange={handleInputChange}
                                            required
                                        />

                                    </div>
                                    <div >
                                        <label className="form-label">Email Template <span className="text-danger">*</span></label>
                                        <Controller
                                        control={control}
                                        name="templateName"
                                        defaultValue={null}
                                        rules={
                                            {
                                            required: 'Please select template'
                                            }
                                        }
                                        render={({ field: { onChange, value, name, ref } }) => (
                                            <Select
                                            name={name}
                                            ref={ref}
                                            options={templateOptions}
                                            className="custom-react-select"
                                            value={templateOptions.find((c) => c.templateName === value)}
                                            required
                                            onChange={(e) => {
                                                handleSelectChange(e);
                                                onChange(e);
                                            }}
                                            />
                                        )}
                                        />
                                    
                                    </div>
                                    <div >
                                        <label></label>
                                        <textarea className="form-control" name="content" rows={10}
                                            value={emailObj.content}
                                            onChange={handleInputChange}
                                            required>
                                        </textarea>

                                    </div>

                                    <div className=" d-flex justify-content-end mt-3">
                                        <button className="btn btn-sm btn-primary me-2">Send</button>
                                        <button className="btn btn-sm btn-primary me-2" onClick={handleCancel}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>


    );
}



export default PropertyShare;