import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { getContextRoot } from "../../../context/Config";
import { useNavigate, useLocation } from "react-router-dom";

function EmailContent() {

  const navigate = useNavigate();

  let [templates, setTemplates] = useState([{
    id: "",
    templateName: "",
    salutation: "",
    subject: "",
    content: "",
    closing: ""
  }]);

  const [edit, setEdit] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [save,setSave]=useState(false);
  const [updatedData,setUpdatedData]=useState({
    id: "",
    templateName: "",
    salutation: "",
    subject: "",
    content: "",
    closing: ""});

  const handleEdit = (index) => {
    // Set the editingIndex to the index of the row being edited
     setEditingIndex(index);
    setEdit(true);
    setUpdatedData(templates[index]);
  };

  const handleDelete = (tempName) => {
    axios.delete(getContextRoot() + `/api/metadata/emailContent/delete/${tempName}`)
      .then((response) => {
        console.log("Data deleted successfully:", response.data);
        toast.success("Data deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        const updatedData = templates.filter((item, i) => item.templateName !== tempName);
        //setMetadata(updatedMetadata);
        setTemplates(updatedData);
      })
      .catch((error) => {
        toast.error("Error deleting data", {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.error("Error deleting data:", error);
      });

  };

  const handleChange = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;
    setUpdatedData({ ...updatedData, [name]: value });
    setSave(true);
  };

  const handleCancel=()=>{
    setEdit(false);
    setSave(false);
    getTemplates();
  }
  const handleSave = () => {

   /* if (isDuplicateTemplate(updatedData.templateName)) {
      toast.error("Duplicate Template Name", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }*/

    templates[editingIndex]=updatedData;

    axios.put(getContextRoot()+`/api/metadata/emailContent/update/${updatedData.id}`, updatedData)
        .then((response) => {
          toast.success("Data updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log("Data updated successfully:", response.data);
          setEdit(false);
          setSave(false);
        })
        .catch((error) => {
          toast.error("Error updating data", {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.error("Error updating data:", error.message);
          getTemplates();
        });
  };

  const isDuplicateTemplate = (templateName) => {
    let duplicate = false;
    templates.map(template => {
      if (template.templateName === templateName) return true;
    });

    return duplicate;

  };

  const getTemplateGrid = () => {
    return (
      <div className="container mt-5 ms-2">
        <div className="text-right">
          <div>
            <button className="btn btn-primary btn-sm" onClick={() => navigate('/add-template')}> Add Template </button>
          </div><br></br>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">S.no</th>
                  <th scope="col">Template Name</th>
                  <th scope="col">Salutation</th>
                  <th scope="col">Subject</th>
                  <th scope="col">Content</th>
                  <th scope="col">Signature Text</th>
                </tr>
              </thead>
              <tbody>
                {
                  templates.map((obj, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {index + 1}
                        </td>
                        <td>
                          {edit&&editingIndex===index? (
                            <input
                              type="text" // Change input type to "number"
                              value={updatedData.templateName}
                              onChange={(e,index) => handleChange(e,index)}
                              className="form-control text-center"
                              placeholder="Enter template name"
                              name="templateName"
                            />
                          ) : (
                            <span>{templates[index].templateName}</span>
                          )}
                        </td>

                        <td>
                        {edit&&editingIndex===index? (
                            <input
                              type="text" // Change input type to "number"
                              value={updatedData.salutation}
                              onChange={(e,index) => handleChange(e,index)}
                              className="form-control text-center"
                              placeholder="Enter salutation"
                              name="salutation"
                            />
                          ) : (
                            <span>{templates[index].salutation}</span>
                          )}

                        </td>
                        <td>
                        {edit&&editingIndex===index? (
                            <input
                              type="text" // Change input type to "number"
                              value={updatedData.subject}
                              onChange={(e,index) => handleChange(e,index)}
                              className="form-control text-center"
                              placeholder="Enter subject"
                              name="subject"
                            />
                          ) : (
                            <span>{templates[index].subject}</span>
                          )}

                        </td>
                        <td>
                        {edit&&editingIndex===index? (
                            <input
                              type="text" // Change input type to "number"
                              value={updatedData.content}
                              onChange={(e,index) => handleChange(e,index)}
                              className="form-control text-center"
                              placeholder="Enter content"
                              name="content"
                            />
                          ) : (
                            <span>{templates[index].content}</span>
                          )}

                        </td>

                        <td>
                        {edit&&editingIndex===index? (
                            <input
                              type="text" // Change input type to "number"
                              value={updatedData.closing}
                              onChange={(e,index) => handleChange(e,index)}
                              className="form-control text-center"
                              placeholder="Enter closing"
                              name="closing"
                            />
                          ) : (
                            <span>{templates[index].closing}</span>
                          )}

                        </td>

                        <td>


                          {save&&editingIndex===index?(
                            <>
                          <td>
                          <button className="btn btn-primary btn-sm" onClick={() => handleSave()}>Save</button>
                          </td>
                          <td>
                          <button className="btn btn-primary btn-sm" onClick={() => handleCancel()}>Cancel</button>
                          </td>
                          </>
                          ):(
                          <FontAwesomeIcon icon={faPenToSquare} className="  fa-lg m-1" onClick={() => handleEdit(index)}/>
                          )}
                        </td>
                        <td>
                          <FontAwesomeIcon icon={faTrash} className="text-danger  fa-lg m-1 "  onClick={() => handleDelete(obj.templateName)}/>
                        </td>

                      </tr>
                    );
                  }
                  )}
              </tbody>
            </table>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }

  useEffect(() => {
    getTemplates();
  }, []);

  /* const getTemplates= async () => {
     //setLoading(true);
 
     try {
       const res = axios.get(getContextRoot() + "/api/metadata/emailContent/all");
 
       //setLoading(false);
       
      // if (Array.isArray(res.data)) {
         setTemplates(res.data);
         
       //} else {
       //  console.error("Error: Response data is not an array");
       //}
     } catch (error) {
       //setLoading(false);
       console.error("Error fetching data:", error);
     }
   };*/

  const getTemplates = () => {
    axios.get(getContextRoot() + "/api/metadata/emailContent/all")
      .then((response) => {
        setTemplates(response.data);
      })
      .catch((error) => {
        toast.error("Error fetching data", {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.error("Error fetching data:", error);
      });
  };

  return (<div>{getTemplateGrid()}</div>);

};



export default EmailContent;
