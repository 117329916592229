import axios from "axios";
import React, { useState } from "react";
import { getContextRoot } from '../../../context/Config';

function XlsUpload(){
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
      };

      const handleUpload = async () => {
        if (!file) return;
      
        try {
          const formData = new FormData();
          formData.append('file', file);

      
          const response = await axios.post(
            //"http://localhost:8086/api/public/project-details/upload-customers-data",
            getContextRoot()+"/api/public/project-details/upload-customers-data",
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data' 
              }
            }
          );
      
          console.log("Excel sheet Data sent to backend successfully", response.data);
        } catch (error) {
          console.error("Error sending data to backend:", error);
        }
      };
    return(
    <>
    <div className="mb-3 col-6 ">
        <label className="form-label">Upload Excel Sheet <span className="text-danger">*</span></label>
    <br />
    <div className="d-flex align-items-center">
                        <input
                            type="file"
                            accept=".xlsx, .xls"
                            name="excel_sheet"
                            className="form-control"
                            onChange={handleFileChange}
                        />
                        <button
                            className="bg-gray p-2 m-2 border-0 "
                            onClick={handleUpload}
                        >
                            Upload
                        </button>
                        </div>
    </div>
    </>
    )
}

export default XlsUpload;