import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Tab, Collapse, FormControl, Form, Button } from "react-bootstrap";
import "rsuite/dist/rsuite.min.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { addCommas, getBasementList, getStatusList } from "../utils";
import PropertyShare from "./PropertyShare";
import { calculateMinMaxValues, getPropertyList, filterApprovedProjects } from "../utils";
import PropertyShareModal from "./PropertyShareModal";

import ModalVideo from "react-modal-video";
import "../../../../node_modules/react-modal-video/scss/modal-video.scss";

import PageTitle from "../../layouts/PageTitle";
import "../../list.css";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignRight,
  faAnchorCircleCheck,
  faBath,
  faBed,
  faBuilding,
  faInfo,
  faPaperPlane,
  faPaperclip,
  faSquare,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";

import property3 from "../../../images/property/3.jpg";
import DemoSlider from "./DemoSlider";
import Search from "../Search/Search";
import { getContextRoot } from "../../../context/Config";

const Rentoption = [
  { value: "1", label: "Default" },
  { value: "2", label: "For Rent" },
  { value: "3", label: "For Sale" },
];


function findMin(array, column) {
  let min = array[0][column];
  for (let i = 1; i < array.length; i++) {
    if (array[i][column] < min) {
      min = array[i][column];
    }
  }
  return min;
}

// Function to find maximum value
function findMax(array, column) {
  let max = array[0][column];
  for (let i = 1; i < array.length; i++) {
    if (array[i][column] > max) {
      max = array[i][column];
    }
  }
  return max;
}

const getCostOfPropertyRange = (property) => {
  const min = findMin(property.projectModelData, "modelCost");
  const max = findMax(property.projectModelData, "modelCost");
  return property.projectModelData.length > 1 && min !== max
    ? `${addCommas(min)} 
      - $${addCommas(max)}`
    : `${addCommas(min)}`;
};
const range = (property, key) => {
  const max = findMax(property.projectModelData, key);
  const min = findMin(property.projectModelData, key);
  if (max !== min && property.projectModelData.length > 1) {
    return `${min} 
        - ${max}`;
  } else if (max === min) {
    return min;
  } else return min;
};



function BasicDetail(props) {
  return (
    <ul>
      <li title="Lots" style={{ marginLeft: "0px", marginRight: "0px" }}>
        <Link to={"#"}>
          <FontAwesomeIcon
            className="text-danger  fa-lg mt-2 ms-3"
            icon={faInfo}
          />
          {props.lotRange}
        </Link>
      </li>
      {/*
      <li
        title="Property Type"
        style={{ marginLeft: "0px", marginRight: "0px" }}
      >
        <Link to={"#"}>
          <FontAwesomeIcon
            className="text-danger  fa-lg mt-2 ms-3"
            icon={faPaperclip}
          />
          {props.type}
        </Link>
      </li>*/}
      <li title="Closing" style={{ marginLeft: "0px", marginRight: "0px" }}>
        <Link to={"#"}>
          <FontAwesomeIcon
            className="text-danger  fa-lg mt-2 ms-3"
            icon={faAnchorCircleCheck}
          />
          {props.closing}
        </Link>
      </li>
      <li>
        <Link to={"#"} style={{ marginLeft: "0px", marginRight: "0px" }}>
          <FontAwesomeIcon
            className="text-danger  fa-lg mt-2 ms-3"
            icon={faSquare}
          />
          {props.area} Sqft
        </Link>
      </li>
      <li title="Builder" style={{ marginLeft: "0px", marginRight: "0px" }}>
        <Link to={"#"}>
          <FontAwesomeIcon
            className="text-danger  fa-lg mt-2 ms-3"
            icon={faBuilding}
          />
          {props.builder}
        </Link>
      </li>
    </ul>
  );
}

const CardFooter = (propertyObj) => {
  const navigate = useNavigate();
  const propertyId = propertyObj.prop.entryId;
  const [showModal, setShowModal] = useState(false);
  const handleClick = () => {
    setShowModal(!showModal);
  };
  


  return (
    <ul>
      <li>
        <Link to={"#"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-heart"
          >
            <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
          </svg>
        </Link>
      </li>
      <li>
        
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#F27424"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-share-2"
            onClick={handleClick}
          >
            <circle cx="18" cy="5" r="3" color="#F27424"></circle>
            <circle cx="6" cy="12" r="3" color="#F27424"></circle>
            <circle cx="18" cy="19" r="3" color="#F27424"></circle>
            <line x1="8.59" y1="13.51" x2="15.42" y2="17.49" color="#F27424"></line>
            <line x1="15.41" y1="6.51" x2="8.59" y2="10.49" color="#F27424"></line>
          </svg>
          {showModal && <PropertyShareModal toggle={showModal} action = {handleClick} propertyId={propertyId} />}
        
      </li>
      <li>
        <Link to={"#"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-plus"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
        </Link>
      </li>
    </ul>
  );
};

const PropertyList = () => {
  const [openVideo, setOpenVideo] = useState(false);
  const [openMenu, setOpenMenu] = useState(true);
  const [openAdvanceSearch, setAdvanceSearch] = useState(false);
  const [result, setResult] = useState([]);

  const navigate = useNavigate();
  const [selectedCards, setSelectedCards] = useState([]);
  const [propertyTypeArr, setPropertyTypeArr] = useState([]);
  const [basementTypeArr, setbasementTypeArr] = useState([]);
  const [statusArr, setStatusArr] = useState([]);
  const [rangeParameters, setRangeParameters] = useState({
    modelCost: [0, 100000000],
    builtUpArea: [0, 1000000],
    frontLotSize: [0, 100],
    sqFt: [0, 10000],
    //bedrooms data type changed to String
    // bedrooms: [0, 50],
    propClosingYear: [0, 2030],
    totalDeposit: [0, 10000000],
  });
  
  const [propertyData, setPropertyData] = useState([]);
  const [search, setSearch] = useState({});
  const toggleSelection = (index) => {
    // Check if the card is already selected
    if (selectedCards.includes(index)) {
      // If selected, remove it from the array
      setSelectedCards(
        selectedCards.filter((cardIndex) => cardIndex !== index)
      );
    } else {
      // If not selected, add it to the array
      setSelectedCards([...selectedCards, index]);
    }
  };

  useEffect(() => {
    getvalues();
  }, []);


  const getvalues = async () => {
    try {
      // const response = await axios.get("http://localhost:8086/api/public/project-details/get-projects-by-approval?approvalStatus=APPROVE");
      const response = await axios.get(
        getContextRoot() +
        "/api/public/project-details/get-projects-by-approval?approvalStatus=APPROVE"
      );
      const updatedData = filterApprovedProjects(response.data, 'APPROVE');
      setPropertyData(updatedData);
      setResult(updatedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (propertyData) setRangeParameters(calculateMinMaxValues(result));
    setPropertyTypeArr(getPropertyList(result));
    setbasementTypeArr(getBasementList(result));
    setStatusArr(getStatusList(result));
  }, [propertyData]);



  function refreshPage() {
    window.location.reload(false);
  }

  const onSubmit = async (
    rangeValues,
    textInputs,
    initialRangeValues,
    initialTextInputs,
    selectedItems
  ) => {
    
    const rangeValuesChanged = Object.keys(rangeValues).some(
      (key) =>
        rangeValues[key][0] !== initialRangeValues[key][0] ||
        rangeValues[key][1] !== initialRangeValues[key][1]
    );
    const textInputsChanged = Object.keys(textInputs).some(
      (key) => textInputs[key] !== initialTextInputs[key]
    );

    if (
      !rangeValuesChanged &&
      !textInputsChanged &&
      !selectedItems &&
      Object.values(textInputs).every((value) => value === "")
    ) {
      setPropertyData(result);
      return;
    }

    let bedroomsInput = '';
    if (textInputs.bedrooms) {
      bedroomsInput = textInputs.bedrooms.trim();
      bedroomsInput = bedroomsInput.includes("+") ?
        bedroomsInput.slice(0, bedroomsInput.indexOf("+") + 1) : bedroomsInput;
    }
     
    const filteredData = result
      .map((project) => {
        const filteredModels = project.projectModelData.filter((modelData) => {

          const bedroomsFilter = () => {

            // Return true if garge input is empty
            if (!textInputs.bedrooms) return true;

            // Return false if model bedrooms is null/empty
            if (!modelData.bedrooms) return false;

            // If model bedrooms contains +, then split bedrooms to get numerical value
            const modelBedRooms = modelData.bedrooms.includes("+") ?
              modelData.bedrooms.slice(0, modelData.bedrooms.indexOf("+")) : modelData.bedrooms;
          
            // Check if bedrooms input ends with + 
            if (bedroomsInput.endsWith("+")) {
              const minBedrooms = parseInt(bedroomsInput.slice(0, -1), 10);
              return modelBedRooms >= minBedrooms;
            } else {
              // Check if bedrooms input is not  a number
              if (isNaN(bedroomsInput)) {
                return modelBedRooms === bedroomsInput;
              } else {
                const exactBedrooms = parseInt(bedroomsInput, 10);
                return modelBedRooms == exactBedrooms;
              }
            }
          };

          const garageFilter = () => {
            
            // Return true if garge input is empty
            if (!textInputs.garage) return true;

            // Return false if model garage is null/empty
            if (!modelData.garage) return false;
            
            const garageInput = textInputs.garage.trim();
            // Check if garage input ends with + 
            if (garageInput.endsWith("+")) {
              const minGarage = parseInt(garageInput.slice(0, -1), 10);
              return modelData.garage >= minGarage;
            } else {
              // Check if garage input is not  a number
              if (isNaN(garageInput)) {
                return modelData.garage === garageInput;
              } else {
                const minGarage = parseInt(garageInput, 10);
                return modelData.garage >= minGarage;
              }
            }
          };

          const basementTypeFilter = () => {
            // Return true if basement type input length is empty
            if (textInputs.basementType.length == 0) return true;
  
            var result = false;
            
            // Check whether basement type exists, if not return false
            if (modelData.basementType) {
              // iterate and compare basement type value exists with basement type input
              textInputs.basementType.some(
                (selectedType) => {
                  result = (modelData.basementType.toLowerCase() === selectedType.toLowerCase());
                  return result;
                });
            } 
  
            return result;
          };

          const propertyTypeFilter = () => {
            // Return true if property type input length is empty
            if (textInputs.propertyType.length == 0) return true;
  
            var result = false;
            
            // Check whether property type exists, if not return false
            if (modelData.propertyType) {
              // iterate and compare property type value exists with property type input
              textInputs.propertyType.some(
                (selectedType) => {
                  result = (modelData.propertyType.toLowerCase() === selectedType.toLowerCase());
                  return result;
                });
            } 
  
            return result;
          };

         
          return (
            modelData.modelCost >= rangeValues.modelCost[0] &&
            modelData.modelCost <= rangeValues.modelCost[1] &&
            modelData.builtUpArea >= rangeValues.builtUpArea[0] &&
            modelData.builtUpArea <= rangeValues.builtUpArea[1] &&
            modelData.frontLotSize >= rangeValues.frontLotSize[0] &&
            modelData.frontLotSize <= rangeValues.frontLotSize[1] &&
            modelData.builtUpArea >= rangeValues.sqFt[0] &&
            modelData.builtUpArea <= rangeValues.sqFt[1] &&
            modelData.approval === "APPROVE" &&
            project.propClosingYear >= rangeValues.propClosingYear[0] &&
            project.propClosingYear <= rangeValues.propClosingYear[1] &&
            modelData.totalDeposit >= rangeValues.totalDeposit[0] &&
            modelData.totalDeposit <= rangeValues.totalDeposit[1] &&
            bedroomsFilter() &&
            garageFilter() &&
            basementTypeFilter() &&
            propertyTypeFilter()
          );
        });
        return {
          ...project,
          projectModelData: filteredModels,
        };
      })
      .filter((project1, index) => {
        const propertyAreasFilter = () => {
          // Return true if pselectedItems input length is empty
          if (selectedItems.length == 0) return true;

          var result = false;
          
          // Check whether property type exists, if not return false
          if (project1.propertyArea) {
            result=selectedItems.includes(project1.propertyArea);
          } 

          return result;
        };  
        const statusFilter = () => {
          // Return true if status  input length is empty
          if (textInputs.status.length == 0) return true;

          var result = false;
          
          // Check whether status  exists, if not return false
          if (project1.status) {
            // iterate and compare status  value exists with status  input
            textInputs.status.some(
              (selectedType) => {
                result = (project1.status.toLowerCase() === selectedType.toLowerCase());
                return result;
              });
          } 

          return result;
        };

        return (
          project1.projectModelData.length > 0 &&
          (!textInputs.projectName ||
            (project1.projectName &&
              project1.projectName
                .toLowerCase()
                .includes(textInputs.projectName.toLowerCase()))) &&
          (!textInputs.developer ||
            (project1.developer &&
              project1.developer
                .toLowerCase()
                .includes(textInputs.developer.toLowerCase()))) &&
          propertyAreasFilter() &&
          statusFilter()
        );
      });

    setPropertyData([...filteredData]);
  };

  const onChange = (event) => {
    let change = { ...search, [event.target.name]: event.target.value };
    Object.keys(change).forEach((key) => {
      if (change[key] === "") {
        delete change[key]; // Remove key with blank value
      }
    });
    setSearch(change);
  };

  const compareProjects = () => {
    if (selectedCards.length < 2) {
      toast.error("Please select more than one projects", {
        className: "custom-toast",
      });
      return;
    }

    navigate(`/compare-property?ids=${selectedCards}`);
  };

  const viewPropertyDetails = (property) => {
    navigate("/property-view-details", { state: { data: property } });
  };

 

  return (
    <>
      <ToastContainer />
      <PageTitle activeMenu={"Property List"} motherMenu={"Property"} />
      {propertyData && rangeParameters && (
        <Search
          openSearch={openMenu}
          openAdvanceSearch={openAdvanceSearch}
          onClickOpenSearch={() => setOpenMenu(!openMenu)}
          onClickOpenAdvanceSearch={() => setAdvanceSearch(!openAdvanceSearch)}
          onChange={onChange}
          onSubmit={onSubmit}
          range={rangeParameters}
          propertyTypeList={propertyTypeArr}
          basementTypeList={basementTypeArr}
          statusList={statusArr}
        />
      )}

      <br />
      <button className="btn btn-primary align-self-center"  onClick={refreshPage}>Clear Filter</button>


      <Tab.Container defaultActiveKey={"Grid"}>
        <div className="my-3 row">
          <div className="col-sm-6 align-self-center">
            <p className="mb-0 d-sm-block d-none">
              {selectedCards.length
                ? `Showing selected cards ${selectedCards.length} of ${propertyData.length}`
                : ""}
            </p>
          </div>
          {/* <div className="col-sm-6">
                        <div className="d-flex justify-content-end align-items-center">
                            <div className="d-flex align-items-center me-2">
                                <label className="form-label me-2 mb-0">Sort by:</label>
                                <Select
                                    options={Rentoption}
                                    defaultValue={Rentoption[0]}
                                    className="custom-react-select"
                                    isSearchable={false}
                                />
                            </div>
                            <Nav as="ul" className="nav nav-pills style-1">
                                <Nav.Item as="li">
                                    <Nav.Link eventKey={'Grid'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-grid"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link eventKey={'List'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </div> */}
        </div>
        <Tab.Content>
          <Tab.Pane eventKey={"Grid"}>
            <div className="row">
              {propertyData.map((property, index) => (
                <div
                  className="col-xl-3 col-xxl-4 col-md-6 col-sm-6 col-lg-4 m-b30"
                  key={index}
                >
                  <div
                    className="property-card style-1"
                    key={index}
                    style={{
                      boxShadow: selectedCards.includes(index)
                        ? "20px 1px 13px #f27424"
                        : "none",
                      animation: selectedCards.includes(property.entryId)
                        ? "shadow-pulse 0.5s infinite"
                        : "none",
                    }}
                    onClick={() => toggleSelection(property.entryId)}
                  >
                    {property.option === "video" ? (
                      <div className="dz-media">
                        <ul>
                          <li className="badge badge-sm badge-primary light">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-map-pin"
                            >
                              <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                              <circle cx="12" cy="10" r="3"></circle>
                            </svg>
                          </li>
                          {/* <li className="rent badge badge-sm badge-primary">For Rent</li> */}
                        </ul>
                        <img src={property3} alt="/" />
                        <Link
                          to={"#"}
                          className="video style-1 video-btn popup-youtube"
                          onClick={() => setOpenVideo(true)}
                        >
                          <i className="fa-solid fa-play"></i>
                        </Link>
                      </div>
                    ) : property.option === "slider" ? (
                      <DemoSlider />
                    ) : (
                      <div className="dz-media">
                        <ul>
                          <li className="badge badge-sm badge-primary light">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-map-pin"
                            >
                              <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                              <circle cx="12" cy="10" r="3"></circle>
                            </svg>
                          </li>
                          {/* <li className="rent badge badge-sm badge-primary">For Rent</li> */}
                        </ul>
                        <img
                          src="https://www.pixelstalk.net/wp-content/uploads/2016/04/Grey-backgrounds-wallpapers-HD.png"
                          alt="/"
                        />
                      </div>
                    )}
                    <div className="dz-content">
                      <h3 className="title">
                        ${getCostOfPropertyRange(property)}
                      </h3>
                      <div className="dz-meta">
                        <BasicDetail
                          area={range(property, "builtUpArea")}
                          garage={range(property, "garage")}
                          closing={property.propClosingYear}
                          lotRange={range(property, "frontLotSize")}
                          bedrooms={range(property, "bedrooms")}
                          bathrooms={range(property, "bathrooms")}
                          basements={property.basementType}
                          status={property.status}
                          type={property.propertyType}
                          builder={property.developer}
                        />
                      </div>
                      <p>{property.inclusion}</p>
                      <hr />

                      <button
                        type="button"
                        class="btn btn-primary mb-3"
                        onClick={() => {
                          viewPropertyDetails(property);
                        }}
                      >
                        {" "}
                        View More
                      </button>

                      <div className="dz-footer">
                        <div className="property-card">
                          <div className="property-media">
                            <img
                              src="https://omah.dexignzone.com/react/demo/static/media/2.5188b4e8963e0b39de97.jpg"
                              alt="/"
                            />
                          </div>
                          <h6 className="title mb-0">{property.projectName}</h6>
                        </div>
                        <CardFooter prop={property} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey={"List"}>
            <div className="row justify-content-lg-center">
              {propertyData.map((property, index) => (
                <div className="col-xl-6 col-lg-8 m-b30" key={index}>
                  <div className="property-card style-1 blog-half">
                    {property.option === "video" ? (
                      <div className="dz-media">
                        <ul>
                          <li className="badge badge-sm badge-primary light">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-map-pin"
                            >
                              <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                              <circle cx="12" cy="10" r="3"></circle>
                            </svg>
                          </li>
                          {/* <li className="rent badge badge-sm badge-primary">For Rent</li> */}
                        </ul>
                        <img src={property.image} alt="/" />
                        <Link
                          to={"#"}
                          className="video style-1 video-btn popup-youtube"
                          onClick={() => setOpenVideo(true)}
                        >
                          <i className="fa-solid fa-play"></i>
                        </Link>
                      </div>
                    ) : (
                      <div className="dz-media">
                        <ul>
                          <li className="badge badge-sm badge-primary light">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-map-pin"
                            >
                              <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                              <circle cx="12" cy="10" r="3"></circle>
                            </svg>
                          </li>
                          {/* <li className="rent badge badge-sm badge-primary">For Rent</li> */}
                        </ul>
                        <img
                          src="https://omah.dexignzone.com/react/demo/static/media/2.5188b4e8963e0b39de97.jpg"
                          alt="/"
                        />
                      </div>
                    )}

                    <div className="dz-content">
                      <h3 className="title">${property.modelCost}</h3>
                      <div className="dz-meta">
                        <BasicDetail
                          beds={property.bedrooms}
                          bath={property.bathrooms}
                          area={property.modelSize}
                        />
                      </div>
                      <p>
                        There are many variations of passages of Lorem Ipsum
                        available, but the majority have. There are many
                        variations of passages.
                      </p>
                      <hr />
                      <div className="dz-footer">
                        <div className="property-card">
                          <div className="property-media">
                            <img
                              src="https://omah.dexignzone.com/react/demo/static/media/2.5188b4e8963e0b39de97.jpg"
                              alt="/"
                            />
                          </div>
                          <h6 className="title mb-0">{property.projectName}</h6>
                        </div>
                        <CardFooter prop={property} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

      <ModalVideo
        channel="youtube"
        autoplay={true}
        youtube={{ mute: 0, autoplay: 0 }}
        isOpen={openVideo}
        videoId="XJb1G9iRoL4"
        onClose={() => setOpenVideo(false)}
      />
      {selectedCards.length ? (
        <button
          style={{ backgroundColor: "#f27424" }}
          class="floating-button"
          onClick={compareProjects}
        >
          Compare
        </button>
      ) : (
        ""
      )}
    </>
  );
};

export default PropertyList;
