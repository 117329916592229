import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { getContextRoot } from '../../../context/Config';

const AddProperty = () => {
  const navigate = useNavigate();

  const [userProperty, setUserProperty] = useState({
    projectName: "",
    propertyType: "",
    propertyArea: "",
    developer: "",
    propClosing: "",
    propClosingYear: 0,
    status: "",
    commission: 0,
    commissionPayment: "",
    developerEmail: "",
    salesRepresentatives: "",
    salesOfficeTelephone: "",
    address: "",
    websiteLink: "",
    //New fields added
    brochure: "",
    siteMap: "",
    priceList: "",
    layouts: "",
    propertyImage: "",
    brochureFileInfo: "",
    siteMapFileInfo: "",
    priceListFileInfo: "",
    layoutsFileInfo: "",
    propertyImageFileInfo: "",
    propertyImageSharePoint: "",
    layoutsSharePoint: "",
    priceListSharePoint: "",
    siteMapSharePoint: "",
    brochureSharePoint: "",
    createdBy: "",
    approval:'NEW',
    notes: ""
  });

  const [propertyId, setPropertyId] = useState(undefined);
  const [statusOptions, setstatusOptions] = useState([]);
  const [propertyTypeOptions, setPropertyTypeOptions] = useState([]);
  const [isNextEnabled,setIsNextEnabled]=useState(false);
  const [isSaveEnabled,setIsSaveEnabled]=useState(true);

  const handleInputChange = (e) => {
      const name = e.target.name;
      const value = e.target.value;
      setUserProperty({ ...userProperty, [name]: value });
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      const name = e.target.name;
      clearErrors(name);
      
      const value = e.target.files[0];
      if (value) {
        const fileName = value.name;
        const fileType = fileName.split('.').pop().toLowerCase();
        //setError(name,'');
        if(name==="propertyImage"){
          if (!['jpeg','jpg','png','svg','bmp'].includes(fileType)) {
            setError(name, {
              message: "Only 'jpeg','jpg','png','svg','bmp' are allowed"
            })
            return;
          }
        }
        else if (!['pdf'].includes(fileType)) {
          setError(name, {
            message: "Only PDF allowed"
          })
  
          return;
        }
        //Check for 10MB
        const fileSize = value.size / 1024 / 1024;
        if (fileSize > 10) {
          setError(name, {
            message: "Size should less than 10 MB"
          });
  
          return;
        }
  
        setUserProperty({ ...userProperty, [name]: fileName, [name + "FileInfo"]: value });
      }
    }
  };

  const { handleSubmit, control, register, clearErrors, setError, formState: { errors } } = useForm();

 

  const onSubmit = async (data) => {
    let userEmail = JSON.parse(localStorage.getItem('userinfo')).email;
    const uProperty = userProperty;
    uProperty.createdBy = userEmail;
    uProperty.propertyType = propertyTypesInfo.response.toString();
    setUserProperty(uProperty);

    const propertyFormData = new FormData();
    
    if(userProperty.brochureFileInfo!=="")
       propertyFormData.append('files', userProperty.brochureFileInfo);
    if(userProperty.siteMapFileInfo!=="")  
      propertyFormData.append('files', userProperty.siteMapFileInfo);
    if(userProperty.priceListFileInfo!=="")
      propertyFormData.append('files', userProperty.priceListFileInfo);
    if(userProperty.layoutsFileInfo!=="")
      propertyFormData.append('files', userProperty.layoutsFileInfo);
    if(userProperty.propertyImageFileInfo!=="")
      propertyFormData.append('files', userProperty.propertyImageFileInfo);

    //propertyFormData.append('files',null);

    const propertyDetailsBlob = new Blob([JSON.stringify(userProperty)], {
      type: 'application/json'
    });

    
    propertyFormData.append("propertyDetails", propertyDetailsBlob);

    try {
      //const response = await axios.post("http://localhost:8086/api/public/project-details/createProperty", propertyFormData);
      const response = await axios.post(getContextRoot()+"/api/public/project-details/createProperty", propertyFormData);
      //console.log("Data sent to backend successfully", response.data);
      //console.log("PropertyId:" + response.propertyId);
      //console.log("Data PropertyId:" + response.data);
      setPropertyId(response.data);
      setIsNextEnabled(true);
      setIsSaveEnabled(false);
      toast.success("Data sent to backend successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      toast.error("Error submitting data", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  

  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {
    try {
      const [statusResponse, propertyTypeResponse] = await axios.all([
       // axios.get("http://localhost:8086/api/metadata/status/all"),
       // axios.get("http://localhost:8086/api/metadata/property/all"),
        axios.get(getContextRoot()+"/api/metadata/status/all"),
        axios.get(getContextRoot()+"/api/metadata/property/all"),
      ]);
      setstatusOptions(statusResponse.data.map(item => ({ value: item.status, label: item.status })));
      setPropertyTypeOptions(propertyTypeResponse.data.map(item => ({ value: item.id, label: item.propertyField })));

    } catch (error) {
      console.error("Error fetching data:", error);
    }
    //console.log(statusOptions);
  };


  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/dashboard");
  }

  const handleNext = (e) => {
    e.preventDefault();
    navigate('/add-property-model', { state: { propertyId } });
  }

  //Property Type checkboxes code
  // validation, at least one medium checkbox needs to be ticked
  const [propertyTypesInfo, setpropertyTypesInfo] = useState({
    propertyTypeInfo: [],
    response: [],
  });

  const handlePropertyTypeOnChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { propertyTypeInfo } = propertyTypesInfo;

    // Case 1 : The user checks the box
    if (checked) {
      setpropertyTypesInfo({
        propertyTypeInfo: [...propertyTypeInfo, value],
        response: [...propertyTypeInfo, value],
      });
    }
    // Case 2  : The user unchecks the box
    else {
      setpropertyTypesInfo({
        propertyTypeInfo: propertyTypeInfo.filter(
          (e) => e !== value
        ),
        response: propertyTypeInfo.filter(
          (e) => e !== value
        ),
      });
    }
  };

  return (
    <>
      <div className="row property">
        <div className="col-12">
          <div className="card" name="PropertyDetails">
            <div className="card-header">
              <h4 className="card-title">Add Project</h4>
            </div>
            <div className="card-body">
              <form className="row g-3" onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="row">
                  <div className="mb-3 model-col-6">
                    <label className="form-label">Project Name <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Project Name"
                      name="projectName"
                      id='projectName'
                      value={userProperty.projectName}
                      {...register('projectName',
                        {
                          required: 'Please enter valid project name',
                          onChange: e => handleInputChange(e)
                        })} />
                    {errors.projectName && <span className="text-danger">{errors.projectName.message}</span>}
                  </div>
                  {/* <div className="mb-3 model-col-6">
                    <label className="form-label">Property Type <span className="text-danger">*</span></label>
                    <br />
                    <div className="add-property-checkboxes">
                      {propertyTypeOptions.map((propertyTypeOption) => (
                        <div style={{ padding: '5px' }}>
                          <input
                            type="checkbox"
                            id={propertyTypeOption.id}
                            name="propertyType"
                            value={propertyTypeOption.label}
                            {...register('propertyType',
                              {
                                required: {
                                  value: true,
                                  message: "At least one property type selection is required"
                                },
                                onChange: e => handlePropertyTypeOnChange(e)
                              })}
                          />

                          <label
                            className="form-check-label"
                            htmlFor={propertyTypeOption.label}
                          >
                            {propertyTypeOption.label}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div>
                      {errors.propertyType && <span className="text-danger">{errors.propertyType.message}</span>} </div>
                  </div> */}
                  <div className="mb-3 model-col-6">
                    <label className="form-label">Project Area <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Project Area"
                      name="propertyArea"
                      id="propertyArea"
                      value={userProperty.propertyArea}
                      {...register('propertyArea',
                        {
                          required: 'Please enter valid property area',
                          onChange: e => handleInputChange(e)
                        })} />
                    {errors.propertyArea && <span className="text-danger">{errors.propertyArea.message}</span>}
                  </div>

                  <div className="mb-3 model-col-6">
                    <label className="form-label">Developer <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Developer"
                      name="developer"
                      id="developer"
                      value={userProperty.developer}
                      {...register('developer',
                        {
                          required: 'Please enter developer',
                          onChange: e => handleInputChange(e)
                        })} />
                    {errors.developer && <span className="text-danger">{errors.developer.message}</span>}
                  </div>
                  <div className="mb-3 model-col-6">
                    <label className="form-label">Project Closing <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Project Closing"
                      name="propClosing"
                      id="propClosing"
                      value={userProperty.propClosing}
                      {...register('propClosing',
                        {
                          required: 'Please enter valid property closing',
                          onChange: e => handleInputChange(e)
                        })} />
                    {errors.propClosing && <span className="text-danger">{errors.propClosing.message}</span>}
                  </div>
                  <div className="mb-3 model-col-6">
                    <label className="form-label">Project Closing Year <span className="text-danger">*</span></label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Closing Year"
                      name="propClosingYear"
                      id="propClosingYear"
                      value={userProperty.propClosingYear}
                      {...register('propClosingYear',
                        {
                          pattern: {
                            value: /^(?:[1-9]|\d\d\d*)$/,
                            message: 'Please enter a number',
                          },
                          onChange: e => handleInputChange(e)
                        })} />
                    {errors.propClosingYear && <span className="text-danger">{errors.propClosingYear.message}</span>}
                  </div>
                  <div className="mb-3 model-col-6">
                    <label className="form-label">Status <span className="text-danger">*</span></label>
                    <Controller
                      control={control}
                      name="status"
                      rules={
                        {
                          required: 'Please select status'
                        }
                      }
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          name={name}
                          ref={ref}
                          options={statusOptions}
                          className="custom-react-select"
                          value={statusOptions.find(option => option.value === userProperty.status)}
                          onChange={(e) => {
                            setUserProperty({
                              ...userProperty,
                              status: e.value,
                            });
                            onChange(e);
                          }}
                        />
                      )}
                    />
                    {errors.status && <span className="text-danger">{errors.status.message}</span>}
                  </div>
                  <div className="mb-3 model-col-6">
                    <label className="form-label">Commission <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Comission"
                      name="comission"
                      id="comission"
                      value={userProperty.commission}

                      {...register('commission',
                        {
                          pattern: {
                            value: /^(?:[1-9]|\d\d\d*)$/,
                            message: 'Please enter a number',
                          },
                          onChange: e => handleInputChange(e)
                        })} />
                    {errors.comission && <span className="text-danger">{errors.comission.message}</span>}
                  </div>
                  <div className="mb-3 model-col-6">
                    <label className="form-label">Comission Payment <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Comission Payement"
                      name="commissionPayment"
                      id="commissionPayment"
                      value={userProperty.commissionPayment}
                      {...register('commissionPayment',
                        {
                          required: 'Please enter valid commission payment',
                          onChange: e => handleInputChange(e)
                        })} />
                    {errors.commissionPayment && <span className="text-danger">{errors.commissionPayment.message}</span>}
                  </div>
                  <div className="mb-3 model-col-6">
                    <label className="form-label">Developer Email <span className="text-danger">*</span></label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email"
                      name="developerEmail"
                      id="developerEmail"
                      value={userProperty.developerEmail}
                      {...register('developerEmail', {
                        required: 'Email is required',
                        pattern: {
                          value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                          message: 'Please enter valid e-mail',
                        },
                        onChange: e => handleInputChange(e)
                      })}

                    />
                    {errors.developerEmail && <span className="text-danger">{errors.developerEmail.message}</span>}

                  </div>
                  <div className="mb-3 model-col-6">
                    <label className="form-label">Sales Representative <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Sales Representative"
                      name="salesRepresentatives"
                      id="salesRepresentatives"
                      value={userProperty.salesRepresentatives}
                      {...register('salesRepresentatives',
                        {
                          required: 'Please enter valid sales representative',
                          onChange: e => handleInputChange(e)
                        })} />
                    {errors.salesRepresentatives && <span className="text-danger">{errors.salesRepresentatives.message}</span>}
                  </div>
                  <div className="mb-3 model-col-6">
                    <label className="form-label">Sales Office Telephone <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Phone Number"
                      name="salesOfficeTelephone"
                      id="salesOfficeTelephone"
                      value={userProperty.salesOfficeTelephone}
                      {...register('salesOfficeTelephone',
                        {
                          required: 'Please enter valid sales office telephone number',
                          onChange: e => handleInputChange(e)
                        })} />
                    {errors.salesOfficeTelephone && <span className="text-danger">{errors.salesOfficeTelephone.message}</span>}
                  </div>
                  <div className="mb-3 model-col-6">
                    <label className="form-label">Developer Address <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Address of your property"
                      name="address"
                      id="address"
                      {...register('address',
                        {
                          required: 'Please enter valid address of your property',
                          onChange: e => handleInputChange(e)
                        })} />
                    {errors.address && <span className="text-danger">{errors.address.message}</span>}
                  </div>
                  
                  
                 
                  <div className="mb-3 model-col-6">
                    <label className="form-label">Project Brochure</label>
                    <input type="file"
                      onChange={handleFileChange}
                      title="Choose project brochure file"
                      id="brochure"
                      name="brochure"
                      accept="pdf"
                      className="form-control"
                    />
                    {errors.brochure && <span className="text-danger">{errors.brochure.message}</span>}
                    <br/>
                     <label className="form-label">Brochure - SharePoint Link</label>
                    <input type="text"
                      id="brochureSharePoint"
                      placeholder="Enter sharepoint link"
                      onChange={handleInputChange}
                      name="brochureSharePoint"
                      className="form-control"
                      value={userProperty.brochureSharePoint} />
                  </div>
                  
                  <div className="mb-3 model-col-6">
                    <label className="form-label">Sitemap</label>
                    <input type="file"
                      title="Choose sitemap file"
                      id="siteMap"
                      name="siteMap"
                      accept="pdf"
                      className="form-control"
                      onChange={handleFileChange} />
                      {errors.siteMap && <span className="text-danger">{errors.siteMap.message}</span>}
                      <br/>
                    <label className="form-label">Sitemap - SharePoint Link</label>
                    <input type="text"
                      id="siteMapSharePoint"
                      placeholder="Enter sharepoint link"
                      onChange={handleInputChange}
                      name="siteMapSharePoint"
                      className="form-control"
                      value={userProperty.siteMapSharePoint} />
                  </div>
                 
                  <div className="mb-3 model-col-6">
                    <label className="form-label">  Price List</label>
                    <input type="file"
                      id="priceList"
                      name="priceList"
                      accept="pdf"
                      className="form-control"
                      onChange={handleFileChange}
                      title="Choose price list file" />
                      {errors.priceList && <span className="text-danger">{errors.priceList.message}</span>}
                      <br/>
                      <label className="form-label">Price List - SharePoint Link</label>
                    <input type="text"
                      id="priceListSharePoint"
                      placeholder="Enter sharepoint link"
                      onChange={handleInputChange}
                      name="priceListSharePoint"
                      className="form-control"
                      value={userProperty.priceListSharePoint} />
                  </div>
                 
                  <div className="mb-3 model-col-6">
                    <label className="form-label">Layouts</label>
                    <input type="file"
                      id="layouts"
                      name="layouts"
                      className="form-control"
                      onChange={handleFileChange}
                      title="Choose layout file" />
                      {errors.layouts && <span className="text-danger">{errors.layouts.message}</span>}
                      <br/>
                      <label className="form-label">Layouts - SharePoint Link</label>
                    <input type="text"
                      id="layoutsSharePoint"
                      placeholder="Enter sharepoint link"
                      onChange={handleInputChange}
                      name="layoutsSharePoint"
                      accept="pdf"
                      className="form-control"
                      value={userProperty.layoutsSharePoint} />
                  </div>
                 
                  <div className="mb-3 model-col-6">
                    <label className="form-label">Project Image</label>
                    <input type="file"
                      id="propertyImage"
                      name="propertyImage"
                      accept="image/jpeg"
                      className="form-control"
                      onChange={handleFileChange} />
                      {errors.propertyImage && <span className="text-danger">{errors.propertyImage.message}</span>}
                      <br/>
                       <label className="form-label">Project Image - SharePoint Link</label>
                    <input type="text"
                      id="propertyImageSharePoint"
                      placeholder="Enter sharepoint link"
                      onChange={handleInputChange}
                      name="propertyImageSharePoint"
                      className="form-control"
                      value={userProperty.propertyImageSharePoint} />
                  </div>

                  <div className="mb-3 model-col-6">
                    <label className="form-label">Website Link <span className="text-danger">*</span></label>
                    <input
                      type="url"
                      className="form-control"
                      placeholder="Enter Website Link"
                      name="websiteLink"
                      id="websiteLink"
                      value={userProperty.websiteLink}
                      {...register('websiteLink',
                        {
                          required: 'Please enter valid website link',
                          pattern: {
                            value: /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/,
                            message: 'Please enter valid website link',
                          },
                          onChange: e => handleInputChange(e)
                        })} />
                    {errors.websiteLink && <span className="text-danger">{errors.websiteLink.message}</span>}
                  </div>

                  <div className="mb-3 model-col-6">
                    <label className="form-label">Notes <span className="text-danger">*</span></label>
                    <textarea
                      className="form-control"
                      placeholder="Enter Notes"
                      name="notes"
                      id='notes'
                      value={userProperty.notes}
                      {...register('notes',
                        {
                          required: 'Please enter Notes',
                          onChange: e => handleInputChange(e)
                        })} />
                    {errors.notes && <span className="text-danger">{errors.notes.message}</span>}
                  </div>

                 
                  <div className=" d-flex justify-content-end mt-3">
                    <button className="btn btn-sm btn-primary me-2" disabled={!isSaveEnabled}>Save</button>
                    <button className="btn btn-sm btn-primary me-2" onClick={handleNext} style={{ display: (isNextEnabled? "block" : "none") }}>Next</button>
                    <button className="btn btn-sm btn-primary me-2" onClick={handleCancel}>Cancel</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default AddProperty;
