// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-details-container {
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  h2, h3 {
    color: #333;
  }
  
  .property-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .property-column {
    background-color: #f0f0f0;
    padding: 10px;
  }
  
  .property-key {
    font-weight: bold;
  }
  
  .model-columns {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  
  .model-column {
    background-color: #f8f8f8;
    padding: 10px;
  }
  
  .model-data {
    display: flex;
    justify-content: space-between;
  }
  
  .model-key {
    font-weight: bold;
  }
  
  .model-value {
    margin-left: 10px;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/jsx/propertyDetailView.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,YAAY;EACd;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,aAAa;IACb,qCAAqC;IACrC,SAAS;EACX;;EAEA;IACE,yBAAyB;IACzB,aAAa;EACf;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,qCAAqC;IACrC,SAAS;EACX;;EAEA;IACE,yBAAyB;IACzB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":[".property-details-container {\n    font-family: Arial, sans-serif;\n    margin: 20px;\n  }\n  \n  h2, h3 {\n    color: #333;\n  }\n  \n  .property-columns {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 10px;\n  }\n  \n  .property-column {\n    background-color: #f0f0f0;\n    padding: 10px;\n  }\n  \n  .property-key {\n    font-weight: bold;\n  }\n  \n  .model-columns {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: 10px;\n  }\n  \n  .model-column {\n    background-color: #f8f8f8;\n    padding: 10px;\n  }\n  \n  .model-data {\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .model-key {\n    font-weight: bold;\n  }\n  \n  .model-value {\n    margin-left: 10px;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
