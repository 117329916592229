// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignUp {
  width: 30%;
  margin: auto;
  background-color: white;
  padding: 20px;
  box-shadow: 4px 4px 4px 3px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-top: 7%; }

/* For Tablet View */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .SignUp {
    width: 40%; } }

/* For Mobile Portrait View */
@media screen and (min-device-width: 375px) and (max-device-width: 480px) {
  .SignUp {
    width: 50%; } }

@media screen and (min-device-width: 320px) and (orientation: portrait) {
  .SignUp {
    width: 80%; } }

@media screen and (min-device-width: 270px) and (orientation: portrait) {
  .SignUp {
    width: 65%; } }
`, "",{"version":3,"sources":["webpack://./src/scss/pages/Registration.scss"],"names":[],"mappings":"AAEA;EACI,UAAU;EACV,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb,8CAA8C;EAC9C,mBAAmB;EACnB,cAAc,EAAA;;AAKhB,oBAAA;AACA;EACE;IACE,UAAU,EAAA,EACX;;AAGH,6BAAA;AACA;EACE;IACE,UAAU,EAAA,EACX;;AAGH;EACE;IACE,UAAU,EAAA,EACX;;AAEH;EACE;IACE,UAAU,EAAA,EACX","sourcesContent":["\n \n.SignUp {\n    width: 30%;\n    margin: auto;\n    background-color: white;\n    padding: 20px;\n    box-shadow: 4px 4px 4px 3px rgba(0, 0, 0, 0.2);\n    border-radius: 10px;\n    margin-top: 7%;\n  }\n  \n  \n  \n  /* For Tablet View */\n  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {\n    .SignUp {\n      width: 40%;\n    }\n  }\n  \n  /* For Mobile Portrait View */\n  @media screen and (min-device-width: 375px) and (max-device-width: 480px) {\n    .SignUp {\n      width: 50%;\n    }\n  }\n  \n  @media screen and (min-device-width: 320px) and (orientation: portrait) {\n    .SignUp {\n      width: 80%;\n    }\n  }\n  @media screen and (min-device-width: 270px) and (orientation: portrait) {\n    .SignUp {\n      width: 65%;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
