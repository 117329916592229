import React from "react";
import "../../propertyDetailView.css"; // Import CSS file for styling
import { Card, Col, Row, Button } from "react-bootstrap"; // Import Card, Row, and Col components from react-bootstrap
import { useNavigate, useLocation } from "react-router-dom";
import { addCommas } from "../utils";
import { getContextRoot } from '../../../context/Config';

const PropertyDetailView = () => {
  const location = useLocation();
  const projectData = location?.state?.data;

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };
  function groupByFrontLotSizeAndPropertyType(data) {
    return data.reduce((acc, item) => {
      const { frontLotSize, propertyType } = item;

      // Ensure the frontLotSize key exists in the accumulator
      // if (!acc[frontLotSize]) {
      //     acc[frontLotSize] = {};
      // }

      // Ensure the propertyType key exists within the frontLotSize key
      if (!acc[`${frontLotSize}"-"${propertyType}`]) {
        acc[`${frontLotSize}"-"${propertyType}`] = [];
      }

      // Push the item into the appropriate group
      acc[`${frontLotSize}"-"${propertyType}`].push(item);

      return acc;
    }, {});
  }


  function getPropertyFileURL(propertyId, fileName) {
    const url = getContextRoot() + "/api/public/project-details/download?filePath=" + propertyId + "/" + fileName;
    return url;
  }


  const renderModal = (data) => {
    const obj = groupByFrontLotSizeAndPropertyType(data);
    //debugger;
    //console.log(obj)
    return Object.keys(obj).map((key) => {
      const { frontLotSize, propertyType } = obj[key][0];
      return (
        <>
          <tr style={{ textAlign: 'center', color: '#ff6746', fontSize: 'large' }}>
            <td style={{ color: '#ff6746', fontWeight: 900 }} colSpan="19">{`Front Lot - ${frontLotSize} | Project Type - ${propertyType}`}</td>
          </tr>
          {obj[key].map((modelData, index) => (<tr>
            <td style={{ color: '#ff6746' }}>{modelData.modelName}</td>
            <td>${modelData.modelCost ? addCommas(modelData.modelCost) : ""}</td>
            <td>{modelData.builtUpArea} sqft</td>
            <td>
              {modelData.modelCost && modelData.builtUpArea
                ? "$" + (modelData.modelCost / modelData.builtUpArea).toFixed(2)
                : ""}
            </td>
            <td>{modelData.storey}</td>
            <td>{modelData.lotDepth}</td>
            <td>{modelData.bedrooms}</td>
            <td>{modelData.bathrooms}</td>
            <td>{modelData.garage}</td>
            <td>{modelData.basement}</td>
            <td>{modelData.basementType}</td>
            <td>{modelData.addOn}</td>
            <td>{modelData.intersection}</td>
            <td>{modelData.projectPhase}</td>
            <td>${modelData.totalDeposit ? addCommas(modelData.totalDeposit) : ""}</td>
            <td style={{ minWidth: '200px' }}>{modelData.depositSubmission}</td>
            <td>${modelData.developmentCharges}</td>
            <td>{modelData.approval}</td>
            <td>{modelData.freehold}</td>
            <td>${modelData.maintainanceAmount}</td>
            <td style={{ minWidth: '200px' }}>{modelData.developerSpecialIncentive}</td>
            
            <td >${modelData.dhreSpecialIncentive}</td>
            <td title={modelData.inclusion} style={{ minWidth: '150px' }}><p>{modelData.inclusion ? modelData.inclusion.substring(0, 50) + '...' : ''}</p></td>

          </tr>))}
        </>
      )
    })
  }

  return (
    projectData && (
      <div className="property-details-container">
        <Row>
          <Col>
            <Card
              className="project-details-card"
              style={{ maxWidth: "100%", margin: "auto" }}
            >
              <Card.Body>
                <Card.Title
                  onClick={() => {
                    navigate("/property-details");
                  }}
                  style={{ color: "#ff6746", cursor: "pointer", fontWeight: 900 }}
                  className="text-center"
                >
                  {projectData.projectName}
                </Card.Title>{" "}
                <table style={{ maxWidth: "100%" }} className="table table-bordered table-responsive" border="1">

                  <thead>
                    <tr>
                      <th>Project Area</th>
                      <th>Project Closing</th>
                      <th>Closing Year</th>
                      <th>Status</th>
                      <th>Commission</th>
                      <th>Commission Payment</th>
                      <th>Developer Email</th>
                      <th>Sales Representatives</th>
                      <th>Address</th>
                      <th>Sales Office Telephone</th>
                      <th>Website Link</th>
                      <th>Approval Status</th>
                      <th>Created At</th>
                      <th>Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{projectData.propertyArea}</td>
                      <td>{projectData.propClosing}</td>
                      <td>{projectData.propClosingYear}</td>
                      <td>{projectData.status}</td>
                      <td>{projectData.commission}</td>
                      <td>{projectData.commissionPayment}</td>
                      <td>{projectData.developerEmail}</td>
                      <td>{projectData.salesRepresentatives}</td>
                      <td>{projectData.address}</td>
                      <td>{projectData.salesOfficeTelephone}</td>
                      <td>
                        <a href="{projectData.websiteLink}">
                          {projectData.websiteLink}
                        </a>
                      </td>
                      <td>{projectData.approval}</td>
                      <td>
                        {new Date(projectData.createdAt).toLocaleString()}
                      </td>
                      <td>{projectData.notes}</td>
                    </tr>
                  </tbody>

                </table>
              </Card.Body>
            </Card>
          </Col>

        </Row>
        <Row>
          <Col>
            <Card
              className="project-details-card"
              style={{ maxWidth: "100%", margin: "auto" }}
            >
              <Card.Body>

                <table style={{ maxWidth: "100%" }} className="table table-bordered table-responsive" border="1">

                  <thead>
                    <tr>
                      <th>Brochure</th>
                      <th>SiteMap</th>
                      <th>Project Image</th>
                      <th>Price List</th>
                      <th>Layouts</th>
                      <th>Brochure Sharepoint</th>
                      <th>SiteMap Sharepoint</th>
                      <th>Project Image Sharepoint</th>
                      <th>Price List Sharepoint</th>
                      <th>Layouts Sharepoint</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><a href={getPropertyFileURL(projectData.entryId, projectData.brochure)} >{projectData.brochure}</a></td>
                      <td><a href={getPropertyFileURL(projectData.entryId, projectData.siteMap)} >{projectData.siteMap}</a></td>
                      <td><a href={getPropertyFileURL(projectData.entryId, projectData.propertyImage)} >{projectData.propertyImage}</a></td>
                      <td><a href={getPropertyFileURL(projectData.entryId, projectData.priceList)} >{projectData.priceList}</a></td>
                      <td><a href={getPropertyFileURL(projectData.entryId, projectData.layouts)} >{projectData.layouts}</a></td>
                      <td>{projectData.brochureSharePoint}</td>
                      <td>{projectData.siteMapSharePoint}</td>
                      <td>{projectData.propertyImageSharePoint}</td>
                      <td>{projectData.priceListSharePoint}</td>
                      <td>{projectData.layoutsSharePoint}</td>
                    </tr>
                  </tbody>

                </table>
              </Card.Body>
            </Card>
          </Col>

        </Row>
        <Row>
          <Card
            className="project-model-card"
            style={{
              maxWidth: "100%",

              border: "none",
            }}
          >
            <Card.Body style={{ overflow: 'scroll' }}>
              <Card.Title
                onClick={() => {
                  navigate("/property-details");
                }}
                style={{ color: "#ff6746", fontWeight: 900, cursor: "pointer" }}
                className="text-center"
              >
                {"Models List"}
              </Card.Title>{" "}
              <table style={{ overflow: 'scroll' }} className="table table-bordered">
                <thead>
                  <tr>
                    <th>Model Name</th>
                    <th>Model Cost</th>
                    <th>Built up Area</th>
                    <th>Average Cost</th>
                    <th>Storey</th>
                    <th>Lot Depth</th>
                    <th>Bedrooms</th>
                    <th>Bathrooms</th>
                    <th>Garage</th>
                    <th>Basement</th>
                    <th>Basement Type</th>
                    <th>Add On</th>
                    <th>Intersection</th>
                    <th>Project Phase</th>
                    <th>Total Deposit</th>
                    <th>Deposit Submission</th>
                    <th>Development Charges</th>
                    <th>Approval Status</th>
                    <th>Freehold</th>
                    <th>Maintenance Amount</th>
                    <th>Developer Special Incentive</th>
                    
                    <th>DHRE Special Incentive</th>
                    <th>Inclusion</th>
                  </tr>
                </thead>
                <tbody>
                  {projectData &&
                    renderModal(projectData.projectModelData)}
                </tbody>
              </table>

            </Card.Body>
          </Card>

        </Row>
        <Button
          className="back-button"
          onClick={handleBack}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "orange",
          }}
        >
          BACK
        </Button>
      </div>
    )
  );
};

export default PropertyDetailView;
