import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getContextRoot } from "../../../../context/Config";
import axiosInstance from "../../../../services/AxiosInstance";
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab } from "react-bootstrap";
const Users = () => {
  let [activeUsers, setActiveUsers] = useState([]);
  let [inActiveUsers, setInActiveUsers] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]);
  let [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  let userEmail = JSON.parse(localStorage.getItem('userinfo')).email;
  let [hasRole, setHasRole] = useState(false);
 
  const getActiveGrid= () => {
    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">S.no</th>
              <th scope="col">User Name</th>
              <th scope="col">Email</th>
              <th scope="col">User Role</th>
              <th scope="col">Registered Date</th>
              <th scope="col"></th>
              <th scope="col">User Status</th>
            </tr>
          </thead>
          {loading
            ? <p className="mt-2 fs-5 fw-bold text-danger">Loading...</p>
            : <tbody>
              {activeUsers.map((obj, index) => {
                if (userEmail !== obj.email) {
                  return (
                    <tr key={index}>
                      <td>
                        {index + 1}
                      </td>
                      <td>
                        {obj.name}
                      </td>

                      <td>
                        {obj.email}
                      </td>

                      <td>
                        <select
                          className="form-select"
                          onChange={handleSelectChange}
                          aria-label="Default select example"
                        >
                          <option selected value={obj.role}>
                            {obj.role}
                          </option>
                          {obj.remainingRoles && obj.remainingRoles.map((item1) => {
                            return (<option value={item1}>{item1}</option>)
                          })}
                        </select>
                      </td>
                      <td>
                        {obj.createdAt}
                      </td>
                      <td>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => saveRole(obj.email)}
                        >
                          Save
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => setUserStatus(obj.email,obj.userStatus==="ACTIVE"?"INACTIVE":"ACTIVE")}
                        >
                          {obj.userStatus==="ACTIVE"?"INACTIVE":"ACTIVE"}
                        </button>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>}
        </table>
      </div>
    );
  }

  const getInActiveGrid = () => {
    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">S.no</th>
              <th scope="col">User Name</th>
              <th scope="col">Email</th>
              <th scope="col">User Role</th>
              <th scope="col">Registered Date</th>
              
              <th scope="col">User Status</th>
            </tr>
          </thead>
          {loading
            ? <p className="mt-2 fs-5 fw-bold text-danger">Loading...</p>
            : <tbody>
              {inActiveUsers.map((obj, index) => {
                if (userEmail !== obj.email) {
                  return (
                    <tr key={index}>
                      <td>
                        {index + 1}
                      </td>
                      <td>
                        {obj.name}
                      </td>

                      <td>
                        {obj.email}
                      </td>

                      <td>
                        {obj.role}
                      </td>
                      <td>
                        {obj.createdAt}
                      </td>
                     
                      <td>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => setUserStatus(obj.email,obj.userStatus==="ACTIVE"?"INACTIVE":"ACTIVE")}
                        >
                          {obj.userStatus==="ACTIVE"?"INACTIVE":"ACTIVE"}
                        </button>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>}
        </table>
      </div>
    );
  }


  
  const getActiveUsers = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');

    try {
      const res = await axiosInstance.get(`/api/private/users/ACTIVE`, { headers: { Authorization: `Bearer ${token}` } });

      setLoading(false);
      
      if (Array.isArray(res.data)) {
        setActiveUsers(res.data);
        
      } else {
        console.error("Error: Response data is not an array");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const getInactiveUsers = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');

    try {
      const res = await axiosInstance.get(`/api/private/users/INACTIVE`, { headers: { Authorization: `Bearer ${token}` } });

      setLoading(false);
      if (Array.isArray(res.data)) {
        setInActiveUsers(res.data);
      } else {
        console.error("Error: Response data is not an array");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };


  const saveRole = async id => {
    const token = localStorage.getItem('token');
    if (hasRole) {
      const res = await axios.post(
        getContextRoot() + `/api/private/give-role?role=${selectedClient}&email=${id}`, {}, { headers: { Authorization: `Bearer ${token}` } }
      );


      if (res.data.message === "Role Updated") {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
         
      } else {
        toast.error("Error, Unable update at this time", {
          position: toast.POSITION.TOP_RIGHT
        });
        
      }
      
      setHasRole(false);
    }
  };

  const setUserStatus = async (id,status) => {
    const token = localStorage.getItem('token');
    
      const res = await axios.post(
        getContextRoot() + `/api/private/setUserStatus?email=${id}&userStatus=${status}`, {}, { headers: { Authorization: `Bearer ${token}` } }
      );
     
   getActiveUsers();
    getInactiveUsers();
  };


  const handleSelectChange = e => {
    setSelectedClient(e.target.value);
    setHasRole(true);
  };

  

  useEffect(() => {
    getActiveUsers();
    getInactiveUsers();
  }, []);
  


  return (
    <div className="container mt-5 ms-2">
      <div className="text-right">
        <div>
          <button className="btn btn-primary btn-sm" onClick={() => navigate('/register')}> Add User </button>
        </div>
      </div>
      <Tabs
        defaultActiveKey="activeUsers"
        id="activeUsers-tabs"
        onSelect={() => {
           
        }}
      >
        <Tab eventKey="activeUsers" title="Active Users">
          {getActiveGrid()}
        </Tab>
        <Tab eventKey="inactiveUsers" title="InActive Users">
          {getInActiveGrid()}
        </Tab>
      </Tabs>

      <ToastContainer />
    </div>
  );
};

export default Users;
