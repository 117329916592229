import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Select from 'react-select';
import React, { useState, useEffect } from "react";
import {
  Collapse,
  Form,
  Button,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import allAreas from "../../../resources/allAreas.json";
import allCities from "../../../resources/allCities.json";

import DropdownTreeSelect from "react-dropdown-tree-select"
import { Checkbox } from "rsuite";
import Box from '@mui/material/Box';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks';
import { height } from "@mui/system";

const Search = ({
  openSearch,
  openAdvanceSearch,
  onClickOpenSearch,
  onClickOpenAdvanceSearch,
  onSubmit,
  hideItems,
  range = {},
  propertyTypeList = [],
  basementTypeList = [],
  statusList=[],
  cities = [],
}) => {

  const [defaultRange, setDefaultRange] = useState(range);
  const defaultTextValues = {
    projectName: "",
    propertyType: [],
    developer: "",
    garage: "",
    bedrooms: "",
    basementType: [],
    status:[],
    areas: [],
    cities: []
  };


  const [selectedItems, setSelectedItems] = React.useState([]);
  const toggledItemRef = React.useRef({});
  const apiRef = useTreeViewApiRef();

  function getItemDescendantsIds(item) {
    const ids = [];
    item.children?.forEach((child) => {
      ids.push(child.id);
      ids.push(...getItemDescendantsIds(child));
    });

    return ids;
  }

  const handleItemSelectionToggle = (event, itemId, isSelected) => {
    toggledItemRef.current[itemId] = isSelected;
  };
  const handleSelectedItemsChange = (event, newSelectedItems) => {
    setSelectedItems(newSelectedItems);
    const itemsToSelect = [];
    const itemsToUnSelect = {};
    Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
      const item = apiRef.current.getItem(itemId);
      if (isSelected) {
        itemsToSelect.push(...getItemDescendantsIds(item));
      } else {
        getItemDescendantsIds(item).forEach((descendantId) => {
          itemsToUnSelect[descendantId] = true;
        });
      }
    });
    const newSelectedItemsWithChildren = Array.from(
      new Set(
        [...newSelectedItems, ...itemsToSelect].filter(
          (itemId) => !itemsToUnSelect[itemId],
        ),
      ),
    );

    setSelectedItems(newSelectedItemsWithChildren);

    toggledItemRef.current = {};
  };



  const handleSelect = (e) => {
    const value = e.target.value;
    const updatedOptions = textInputs.propertyType.includes(value)
      ? textInputs.propertyType.filter(option => option !== value)
      : [...textInputs.propertyType, value];

    // Create a synthetic event object to use handleTextChange
    const syntheticEvent = {
      target: {
        name: 'propertyType',
        value: updatedOptions,
      },
    };
    handleTextChange(syntheticEvent);
  };

  const handleAreaSelect = (e) => {
    const value = e.target.value;
    const updatedOptions = textInputs.areas.includes(value)
      ? textInputs.areas.filter(option => option !== value)
      : [...textInputs.areas, value];

    // Create a synthetic event object to use handleTextChange
    const syntheticEvent = {
      target: {
        name: 'areas',
        value: updatedOptions,
      },
    };

    const dt = allCities.filter(city => updatedOptions.includes(city.aid));
    setCity(dt);
    textInputs.cities = [];

    dt.map(cty => {
      allCities.push(cty.label);
      textInputs.cities.push(cty.id);
    });

    setSelectedItems(textInputs.cities);
    handleTextChange(syntheticEvent);
  };

  const handleCitySelect = (e) => {
    const value = e.target.value;
    const updatedOptions = textInputs.cities.includes(value)
      ? textInputs.cities.filter(option => option !== value)
      : [...textInputs.cities, value];

    // Create a synthetic event object to use handleTextChange
    const syntheticEvent = {
      target: {
        name: 'cities',
        value: updatedOptions,
      },
    };
    setSelectedItems(updatedOptions);
    handleTextChange(syntheticEvent);
  };


  const handleBasementSelect = (e) => {
    const value = e.target.value;
    const updatedOptions = textInputs.basementType.includes(value)
      ? textInputs.basementType.filter(option => option !== value)
      : [...textInputs.basementType, value];

    // Create a synthetic event object to use handleTextChange
    const syntheticEvent = {
      target: {
        name: 'basementType',
        value: updatedOptions,
      },
    };
    handleTextChange(syntheticEvent);
  };

  const handleStatusSelect = (e) => {
    const value = e.target.value;
    const updatedOptions = textInputs.status.includes(value)
      ? textInputs.status.filter(option => option !== value)
      : [...textInputs.status, value];

    // Create a synthetic event object to use handleTextChange
    const syntheticEvent = {
      target: {
        name: 'status',
        value: updatedOptions,
      },
    };
    handleTextChange(syntheticEvent);
  };


  const [rangeValues, setRangeValues] = useState(range);

  useEffect(() => {
    if (range) {
      setDefaultRange(range);
      setRangeValues(range);
    }
  }, [range]);
  const handleRangeChange = (name, value) => {
    setRangeValues({
      ...rangeValues,
      [name]: value,
    });
    // Notify the parent component of the change
    // onChange({ target: { name, value } });
  };
  const [textInputs, setTextInputs] = useState({
    projectName: "",
    propertyType: [],
    developer: "",
    garage: "",
    bedrooms: "",
    basementType: [],
    status:[],
    cities: [],
    areas: []
  });
  const handleTextChange = (event) => {
    const { name, value } = event.target;
    setTextInputs({
      ...textInputs,
      [name]: value,
    });
    //onChange(event);
  };
  const dropdownStyles = {
    border: "1px solid #white",
    borderRadius: "0.50rem",
    width: "72%",
    margin: "1.5px",
  };

  const dropdownMenuStyles = {
    width: "100%",
    height: "130px",
    overflowY: "scroll",
    paddingLeft: "10px",
  };

  function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  ///
  const [area, setArea] = useState([]);
  const [city, setCity] = useState([]);


  useEffect(() => {
    setArea(allAreas);
  }, []);

  ///
  return (
    Object.keys(range).length && (
      <>
        <div className="row">
          <div className="col-12">
            <div className="filter cm-content-box box-primary">
              <div
                className="content-title SlideToolHeader"
                onClick={onClickOpenSearch}
              >
                <div className="cpa">
                  <i className="fa-sharp fa-solid fa-filter me-2" />
                  Filter
                </div>
                <div className="tools">
                  <Link
                    to={"#"}
                    className={`handle ${openSearch ? "expand" : "collapse"}`}
                  >
                    <i className="fal fa-angle-down" />
                  </Link>
                </div>
              </div>
              <Collapse in={openSearch}>
                
                  <div
                    className="flex-container"
                    style={{
                      justifyContent: hideItems ? "center" : "flex-start",
                    }}
                  >
                    {/* Section 1 */}
                    <div id="section1" className="search-box-item row width-box collapse-container">
                      <div
                        id="section1.1"
                        className="search-box-item flex-container column"
                      >
                        {/* Row 1 */}
                        <div className="row">
                          <div className="col">
                            {/* First text box */}
                            <label>
                              Project Name
                            </label>
                            <Form.Group
                              controlId="textbox1"
                              style={{ width: "95%" }}

                            >
                              <Form.Control
                                type="text"
                                name="projectName"
                                onChange={handleTextChange}
                                placeholder="Enter Project Name"
                              />
                              <br />

                            </Form.Group>

                          </div>
                          <div className="col" style={dropdownStyles}>
                            <label>
                              Area
                            </label>
                            <div style={dropdownStyles}>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="light"
                                  id="dropdown-basic-area"
                                  title={textInputs.areas.length > 0
                                    ? textInputs.areas.join(", ")
                                    : "Select Area"}
                                  style={{
                                    width: "130%",
                                    borderRadius: "0.70rem",
                                    background: "white",
                                    color: "#9e9e9e",
                                  }}
                                >

                                  {textInputs.areas.length > 0
                                    ? (textInputs.areas.length > 2 ?
                                      textInputs.areas.slice(0, 2).join(", ").concat("...") :
                                      textInputs.areas.join(", "))
                                    : "Select Area"}
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={dropdownMenuStyles}>
                                  {area.map((ara, index) => (
                                    <div key={index}>
                                      <Form.Check
                                        type="checkbox"
                                        label={ara.id}
                                        value={ara.label}
                                        checked={textInputs.areas.includes(ara.id)}
                                        onChange={handleAreaSelect}
                                      />
                                    </div>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>

                          </div>
                          <div className="col" style={dropdownStyles}>
                            <label>
                              City
                            </label>
                            <div style={dropdownStyles}>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="light" maxHeight={500} overflowY="scroll"
                                  id="dropdown-basic-city"
                                  title={textInputs.cities.length > 0
                                    ? textInputs.cities.join(", ")
                                    : "Select City"}
                                  style={{
                                    width: "130%",
                                    borderRadius: "0.70rem",
                                    background: "white",
                                    color: "#9e9e9e",
                                  }}
                                >
                                  {textInputs.cities.length > 0
                                    ? (textInputs.cities.length > 2 ?
                                      textInputs.cities.slice(0, 2).join(", ").concat("...") :
                                      textInputs.cities.join(", "))
                                    : "Select City"}
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={dropdownMenuStyles}>
                                  {city.map((cty, index) => (
                                    <div key={index}>
                                      <Form.Check
                                        type="checkbox"
                                        label={cty.id}
                                        value={cty.label}
                                        checked={textInputs.cities.includes(cty.id)}

                                        onChange={handleCitySelect}
                                      />
                                    </div>
                                  ))}
                                </Dropdown.Menu>

                              </Dropdown>
                            </div>
                          </div>
                          <div className="col" style={dropdownStyles}>
                            <label>
                              Property Type
                            </label>
                            <div style={dropdownStyles}>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="light"
                                  id="dropdown-basic"
                                  title={textInputs.propertyType.length > 0
                                    ? textInputs.propertyType.join(", ")
                                    : "Select Project Type"}
                                  style={{
                                    width: "130%",
                                    borderRadius: "0.70rem",
                                    background: "white",
                                    color: "#9e9e9e",
                                  }}
                                >
                                  {textInputs.propertyType.length > 0
                                    ? (textInputs.propertyType.length > 1 ?
                                      textInputs.propertyType.slice(0, 1).join(", ").concat("...") :
                                      textInputs.propertyType.join(", "))
                                    : "Select Project Type"}
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={dropdownMenuStyles}>
                                  {propertyTypeList.map((option) => (
                                    <div key={option}>
                                      <Form.Check
                                        type="checkbox"
                                        label={option}
                                        value={option}
                                        checked={textInputs.propertyType.includes(option)}
                                        onChange={handleSelect}
                                      />
                                    </div>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            {/* Budget range */}
                            <Form.Group
                              controlId="textbox3"
                              style={{ width: "50%" }}
                            >
                              <br />
                              <Form.Label>
                                Budget: {currencyFormat(rangeValues.modelCost[0])} -{" "}
                                {currencyFormat(rangeValues.modelCost[1])}
                              </Form.Label>
                              <Slider
                                range
                                min={defaultRange.modelCost[0]}
                                max={defaultRange.modelCost[1]}
                                step={10000}
                                value={rangeValues.modelCost}
                                onChange={(value) =>
                                  handleRangeChange("modelCost", value)
                                }
                              />
                              <div className="d-flex justify-content-between">
                                <span>{defaultRange.modelCost[0]}</span>
                                <span>{defaultRange.modelCost[1]}</span>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col" style={{ margin: "17px 0px 0px 0px" }}>

                            {/* Section 2
                    {!hideItems && (
                      <div id="section2" className="search-box-item">
                        Campaign 1
                      </div>
                    )}*/}
                            {/* Section 3
                    {!hideItems && (
                      <div id="section3" className="search-box-item">
                        Campaign 2{" "}
                      </div>
                    )}*/}
                            {/* Section 4
                    {!hideItems && (
                      <div id="section4" className="search-box-item">
                        Campaign 3
                      </div>
                    )}
                  {!hideItems && (
                      <div id="section5" className="search-box-item" style={{ width: "70%" }}>
                        Total Number of Projects{" "}
                      </div>
                    )}*/}
                          </div>
                        </div>

                      </div>
                      {/* Row 2 */}

                      <div
                        id="section1.2"
                        className="flex-container search-box-item column button"
                      >

                        <br />
                        <Button
                          onClick={() => {
                            onSubmit(
                              rangeValues,
                              textInputs,
                              defaultRange,
                              defaultTextValues,
                              selectedItems
                            );
                          }}
                        >
                          Search
                        </Button>
                      </div>
                    </div>
                  </div>
                
              </Collapse>
            </div>
          </div>
        </div>

        {/**Second */}
        <div className="row">
          <div className="col-12">
            <div className="filter cm-content-box box-primary">
              <div
                className="content-title SlideToolHeader"
                onClick={onClickOpenAdvanceSearch}
              >
                <div className="cpa">
                  <i className="fa-sharp fa-solid fa-filter me-2" />
                  Advance Search
                </div>
                <div className="tools">
                  <Link
                    to={"#"}
                    className={`handle ${openAdvanceSearch ? "expand" : "collapse"
                      }`}
                  >
                    <i className="fal fa-angle-down" />
                  </Link>
                </div>
              </div>
              <Collapse in={openAdvanceSearch}>
                <div
                  id="section1"
                  className="search-box-item width-box row width-box collapse-container"
                >
                  <div
                    id="section1.1"
                    className="search-box-item flex-container column"
                  >
                    <div className="row">
                      <div className="col">
                        {/*bedrooms text*/}
                        <label>Bedrooms</label>
                        <Form.Group
                          controlId="textbox7"
                          style={{ width: "70%" }}
                        >
                          <Form.Control
                            type="text"
                            onChange={handleTextChange}
                            name="bedrooms"
                            placeholder="Bedrooms"
                          />
                        </Form.Group>
                      </div>
                      <div className="col">
                        {/* Garage text box */}
                        <label>Garage</label>
                        <Form.Group
                          controlId="textbox2"
                          style={{ width: "70%" }}
                        >
                          <Form.Control
                            type="text"
                            onChange={handleTextChange}
                            name="garage"
                            placeholder="Garage"
                          />
                        </Form.Group>
                      </div>
                      <div className="col">
                        <label>BasementType</label>
                        <div style={dropdownStyles}>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="light"
                              id="dropdown-basic"
                              style={{
                                width: "100%",
                                borderRadius: "0.70rem",
                                background: "white",
                                color: "#9e9e9e",
                              }}
                            >

                              {textInputs.basementType.length > 0

                                ? textInputs.basementType.join(", ")
                                : "Select Basement Type"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={dropdownMenuStyles}>
                              {basementTypeList.map((option) => (
                                <div key={option}>
                                  <Form.Check
                                    type="checkbox"
                                    label={option}
                                    value={option}
                                    checked={textInputs.basementType.includes(option)}
                                    onChange={handleBasementSelect}
                                  />
                                </div>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>

                      <div className="col">
                        {/* Developer text box */}
                        <label>Developer</label>
                        <Form.Group
                          controlId="textbox5"
                          style={{ width: "70%" }}
                        >
                          <Form.Control
                            type="text"
                            onChange={handleTextChange}
                            name="developer"
                            placeholder="Developer"
                          />
                        </Form.Group>
                      </div>

                    </div>
                    <div className="row">
                      <div className="col">
                        {/* Front Lot range */}
                        <Form.Group
                          controlId="textbox1"
                          style={{ width: "70%" }}
                        >
                          <Form.Label>
                            Front Lot: {rangeValues.frontLotSize[0]} -{" "}
                            {rangeValues.frontLotSize[1]}
                          </Form.Label>
                          <Slider
                            range
                            min={defaultRange.frontLotSize[0]}
                            max={defaultRange.frontLotSize[1]}
                            step={1}
                            value={rangeValues.frontLotSize}
                            onChange={(value) =>
                              handleRangeChange("frontLotSize", value)
                            }
                          />
                          <div className="d-flex justify-content-between">
                            <span>{defaultRange.frontLotSize[0]}</span>
                            <span>{defaultRange.frontLotSize[1]}</span>
                          </div>
                        </Form.Group>
                      </div>
                      {/*<div className="col">
                        {/* Developer text box }
                        <Form.Group
                          controlId="textbox5"
                          style={{ width: "70%" }}
                        >
                          <Form.Control
                            type="text"
                            onChange={handleTextChange}
                            name="developer"
                            placeholder="Developer"
                          />
                        </Form.Group>
                      </div>
                       {/*<div className="col">
                       {/* Basement text box }
                        <Form.Group
                          controlId="textbox6"
                          style={{ width: "70%" }}
                        >
                          <Form.Control
                            type="text"
                            onChange={handleTextChange}
                            name="basement"
                            placeholder="Basement"
                          />
                        </Form.Group>
                      </div>*/}

                      <div className="col">
                        {/* Sq-Ft range */}
                        <Form.Group
                          controlId="textbox4"
                          style={{ width: "70%" }}
                        >
                          <Form.Label>
                            Sq-Ft: {rangeValues.sqFt[0]} - {rangeValues.sqFt[1]}
                          </Form.Label>
                          <Slider
                            range
                            min={defaultRange.sqFt[0]}
                            max={defaultRange.sqFt[1]}
                            step={10}
                            value={rangeValues.sqFt}
                            onChange={(value) =>
                              handleRangeChange("sqFt", value)
                            }
                          />
                          <div className="d-flex justify-content-between">
                            <span>{defaultRange.sqFt[0]}</span>
                            <span>{defaultRange.sqFt[1]}</span>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col">
                        {/* Closing year range */}
                        <Form.Group
                          controlId="textbox8"
                          style={{ width: "70%" }}
                        >
                          <Form.Label>
                            Closing Year: {rangeValues.propClosingYear[0]} -{" "}
                            {rangeValues.propClosingYear[1]}
                          </Form.Label>
                          <Slider
                            range
                            min={defaultRange.propClosingYear[0]}
                            max={defaultRange.propClosingYear[1]}
                            step={1}
                            value={rangeValues.propClosingYear}
                            onChange={(value) =>
                              handleRangeChange("propClosingYear", value)
                            }
                          />
                          <div className="d-flex justify-content-between">
                            <span>{defaultRange.propClosingYear[0]}</span>
                            <span>{defaultRange.propClosingYear[1]}</span>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col">
                        <label>Status</label>
                        <div style={dropdownStyles}>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="light"
                              id="dropdown-status"
                              style={{
                                width: "100%",
                                borderRadius: "0.70rem",
                                background: "white",
                                color: "#9e9e9e",
                              }}
                            >

                              {textInputs.status.length > 0

                                ? textInputs.status.join(", ")
                                : "Select Status"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={dropdownMenuStyles}>
                              {statusList.map((option) => (
                                <div key={option}>
                                  <Form.Check
                                    type="checkbox"
                                    label={option}
                                    value={option}
                                    checked={textInputs.status.includes(option)}
                                    onChange={handleStatusSelect}
                                  />
                                </div>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>

                      </div>

                    </div>

                  </div>
                  {/* Row 2 */}
                  <div
                    id="section1.2"
                    className="flex-container search-box-item column button"
                  >
                    <Button
                      onClick={() => {
                        onSubmit(
                          rangeValues,
                          textInputs,
                          defaultRange,
                          defaultTextValues,
                          selectedItems
                        );
                      }}
                    >
                      Search
                    </Button>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default Search;
