import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect, useReducer } from "react";
import { useForm, Controller } from 'react-hook-form';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { getContextRoot } from "../../../context/Config";
import Select from "react-select";
import { Dropdown, Button, Modal } from "react-bootstrap";


function PropertyShareModal(props) {
    const toggle = props.toggle;
    const action = props.action;
    const propertyId = props.propertyId;
    const [emailObj, setEmailObj] = useState({
        from: "",
        to: "",
        subject: "",
        projectId: "",
        content: ""
    });

    let [templates, setTemplates] = useState([{
        templateName: "Select template",
        salutation: "",
        subject: "",
        content: "",
        closing: ""
    }]);
    const [templateOptions, setTemplateOptions] = useState([]);

    const getTemplates = () => {
        axios.get(getContextRoot() + "/api/metadata/emailContent/all")
            .then((response) => {
                setTemplates(response.data);
                setTemplateOptions(response.data.map(item => ({ value: item.templateName, label: item.templateName })));

            })
            .catch((error) => {
                toast.error("Error fetching data", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                console.error("Error fetching data:", error);
            });
    };
    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setEmailObj({ ...emailObj, [name]: value });
    };


    const handleSelectChange = (e) => {

        const value = e.value;
        templates.filter(template => template.templateName === value)
            .map(temp => (
                setEmailObj({
                    ...emailObj,
                    ["content"]: temp.salutation + ",\n\n" + temp.content + "\n\n <ALLLINKS>" + "\n\n" + temp.closing,
                    ["subject"]: temp.subject
                })

            ));

    };


    const { handleSubmit, control } = useForm();
    const onSubmit = async (data) => {

        let agentEmail = JSON.parse(localStorage.getItem('userinfo')).email;

        emailObj.from = agentEmail;

        emailObj.projectId = props.propertyId.toString();
        try {
            const response = axios.post(getContextRoot() + "/api/public/project-details/shareProject", emailObj);
            toast.success("Data sent to backend successfully", {
                position: toast.POSITION.TOP_RIGHT,
            });

            action();//Closes Modal

        } catch (error) {
            toast.error("Error submitting data", {
                position: toast.POSITION.TOP_RIGHT,
            });
            console.error("Error sending data to backend:", error);
        }
    }

    useEffect(() => {
        getTemplates();
    }, []);



    return (
        <>
            <Modal show={toggle} onHide={action}>
                <Modal.Header closeButton>
                    <Modal.Title>Send Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/*{propertyId}*/}

                    <div className="modal-body">
                        <form className="comment-form"
                            onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col">
                                    <div>
                                        <label className="text-black font-w600">  To: <span className="required">*</span> </label>
                                        <input
                                            type="email" multiple
                                            className="form-control"
                                            placeholder="Email"
                                            name="to"
                                            value={emailObj.to}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>
                            </div><div className="row">
                                <div className="col mt-2">
                                    <div className="form-group mb-3">
                                        <label htmlFor="email" className="text-black font-w600"> Email Template<span className="required">*</span></label>
                                        <Controller
                                            control={control}
                                            name="templateName"
                                            defaultValue={null}
                                            rules={
                                                {
                                                    required: 'Please select template'
                                                }
                                            }
                                            render={({ field: { onChange, value, name, ref } }) => (
                                                <Select
                                                    name={name}
                                                    ref={ref}
                                                    options={templateOptions}
                                                    className="custom-react-select"
                                                    value={templateOptions.find((c) => c.templateName === value)}
                                                    required
                                                    onChange={(e) => {
                                                        handleSelectChange(e);
                                                        onChange(e);
                                                    }}
                                                />
                                            )}
                                        />

                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                        <textarea rows={8} className="form-control" name="content" placeholder="content"
                                            onChange={handleInputChange}
                                            value={emailObj.content}
                                            required />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                        <button className="btn btn-sm btn-primary me-2">Send</button>
                                        <button className="btn btn-sm btn-primary me-2" onClick={action}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>


                </Modal.Body>

            </Modal>
        </>
    );
}

export default PropertyShareModal;