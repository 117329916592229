import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form";
import axios from "axios";
import { getContextRoot } from '../../../../context/Config';


const AddModel = () => {
    const [file, setFile] = useState(null);
    const [rangeValue, setRangeValue] = useState(0);
    const [modelProperties, setModelProperties] = useState({
        projectType: "",
        log: "",
        modelName: "",
        modelNumber: "",
        fronLotSize: "",
        noOfBedRooms: "",
        noOfBathRooms: "",
        sqFT: "",
        Story: "",
        lotDepth: "",
        garage: "",
        basement: "",
        basementType: "",
        inclusion: "",
        modelCost: "",
        depositStructure:[ {
            id: 0,
            price: "",
            days: "",
            percentage:""
        }],
        freehold: "",
        maintananceAmomunt: "",
        DeveloperSpecialIncentive: "",
        DHRESpecialIncentive: "",
        addOn: "",
        projectPhase: "",
        intersection: "",
        websiteLink:""
    });
    const calculateValues = () => {
        const price = rangeValue * 10;
        const days = rangeValue / 10;
        const percentage = rangeValue / 100;
        return { price, days, percentage };
    }
    const { price, days, percentage } = calculateValues();
    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setModelProperties({ ...modelProperties, [name]: value });
    };
    const handleRangeChange = (e) => {
        setRangeValue(parseInt(e.target.value));
    };
    const { handleSubmit } = useForm();
    const onSubmit = async (data) => {
        
        try {
            const response = await axios.post(
               // "http://localhost:8086/api/public/project-details/create",
               getContextRoot()+"/api/public/project-details/create",
                null
            );
            //console.log("Data sent to backend successfully", response.data);
            // Optionally, you can handle the response from the server here
        } catch (error) {
            console.error("Error sending data to backend:", error);
        }
    };
    const handleUpload = async () => {
        if (!file) return;

        try {
            const formData = new FormData();
            formData.append('file', file);

            const response = await axios.post(
                //"http://localhost:8086/api/public/project-details/upload-customers-data",
                getContextRoot()+"/api/public/project-details/upload-customers-data",
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            console.log("Excel sheet Data sent to backend successfully", response.data);
        } catch (error) {
            console.error("Error sending data to backend:", error);
        }
    };
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };
    return (
        <>
            <div className="row property">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Add Models</h4>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="mb-3 col-6">
                                <label className="form-label">Model Name <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Model Name"
                                    name="projectName"
                                    value=""
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="mb-3 col-6">
                                <label className="form-label">Model Number <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Model Number"
                                    name="projectName"
                                    value=""
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="mb-3 col-6">
                                <label className="form-label">Front Lot Size<span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Front Lot Size"
                                    name="projectName"
                                    value=""
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="mb-3 col-6">
                                <label className="form-label">Number of Bed Rooms<span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Number of Bed Rooms"
                                    name="projectName"
                                    value=""
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="mb-3 col-6">
                                <label className="form-label">Number of Bath Rooms<span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Number of Bath Rooms"
                                    name="projectName"
                                    value=""
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="mb-3 col-6">
                                <label className="form-label">SQFT<span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter SQFT"
                                    name="projectName"
                                    value=""
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="mb-3 col-6">
                                <label className="form-label">Storey<span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Storey"
                                    name="projectName"
                                    value=""
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="mb-3 col-6">
                                <label className="form-label">Lot Depth<span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Lot Depth"
                                    name="projectName"
                                    value=""
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className="mb-3 col-6">
                                <label className="form-label">Garage<span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Garage"
                                    name="projectName"
                                    value=""
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className="mb-3 col-6">
                                <label className="form-label">Basement Type<span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Basement Type"
                                    name="projectName"
                                    value=""
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className="mb-3 col-6">
                                <label className="form-label">Inclusion<span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Inclusion"
                                    name="projectName"
                                    value=""
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className="mb-3 col-6">
                                <label className="form-label">Model Cost<span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Model Cost"
                                    name="projectName"
                                    value=""
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                        <hr className="my-4" />
                        <div className="mb-3 col-6" style={{ overflowX: "auto" }}>
                            <label className="form-label">
                                Deposit with Agreement/Offer
                                <span className="text-danger">*</span>
                            </label>
                            <div className="d-flex align-items-center">
                                <button className="btn btn-outline-primary me-2">
                                    Price :
                                </button>
                                <button className="btn btn-outline-primary me-2">
                                    Days : 
                                </button>
                                <button className="btn btn-outline-primary me-2">
                                    Percentage : 
                                </button>
                            </div>
                            <input
                                type="range"
                                className="form-range mt-3"
                                min="0"
                                max="10000"
                                step="100"
                                value={rangeValue}
                                onChange={ handleRangeChange}
                            />
                        </div>

                        <div className="mb-3 col-6" style={{ overflowX: "auto" }}>
                            <label className="form-label">Deposit structure 2 <span className="text-danger">*</span></label>
                            <div className="d-flex align-items-center">
                                <button className="btn btn-outline-primary me-2">
                                    Price: {price}
                                </button>
                                <button className="btn btn-outline-primary me-2">
                                    Days: {days}
                                </button>
                                <button className="btn btn-outline-primary me-2">
                                    Percentage:{percentage}%
                                </button>
                            </div>
                            <input
                                type="range"
                                className="form-range"
                                min="0"
                                max="10000"
                                step="100"
                                value={rangeValue}
                                onChange={handleRangeChange}
                            />
                        </div>

                        <div className="mb-3 col-6" style={{ overflowX: "auto" }}>
                            <label className="form-label">Deposit structure 1 <span className="text-danger">*</span></label>
                            <div className="d-flex align-items-center">
                                <button className="btn btn-outline-primary me-2">
                                    Price: {price}
                                </button>
                                <button className="btn btn-outline-primary me-2">
                                    Days: {days}
                                </button>
                                <button className="btn btn-outline-primary me-2">
                                    Percentage: {percentage}%
                                </button>
                            </div>
                            <input
                                type="range"
                                className="form-range"
                                min="0"
                                max="10000"
                                step="100"
                                value={rangeValue}
                                onChange={handleRangeChange}
                            />
                        </div>

                        <div className="mb-3 col-6" style={{ overflowX: "auto" }}>
                            <label className="form-label">Deposit structure 3 <span className="text-danger">*</span></label>
                            <div className="d-flex align-items-center">
                                <button className="btn btn-outline-primary me-2">
                                    Price: {price}
                                </button>
                                <button className="btn btn-outline-primary me-2">
                                    Days: {days}
                                </button>
                                <button className="btn btn-outline-primary me-2">
                                    Percentage: {percentage}%
                                </button>
                            </div>
                            <input
                                type="range"
                                className="form-range"
                                min="0"
                                max="10000"
                                step="100"
                                value={rangeValue}
                                onChange={handleRangeChange}
                            />
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">Total Deposit<span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Project Name"
                                name="projectName"
                                value=""
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">Development Charges<span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Project Name"
                                name="projectName"
                                value=""
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">Freehold<span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Project Name"
                                name="projectName"
                                value=""
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">Maintenance Amount<span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Project Name"
                                name="projectName"
                                value=""
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">Developer spcecial incentive<span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Project Name"
                                name="projectName"
                                value=""
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">DHRE Special Incentive<span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Project Name"
                                name="projectName"
                                value=""
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">Add on<span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Project Name"
                                name="projectName"
                                value=""
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">Project Phase<span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Project Name"
                                name="projectName"
                                value=""
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">Intersection<span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Project Name"
                                name="projectName"
                                value=""
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">Web Site Link<span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Project Name"
                                name="projectName"
                                value=""
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className="mb-3 col-6 ">
                            <label className="form-label">Attach Files <span className="text-danger">*</span></label>
                            <br />
                            <div className="d-flex align-items-center">
                                <input
                                    type="file"
                                    accept=".xlsx, .xls"
                                    name="excel_sheet"
                                    className="form-control"
                                    onChange={handleUpload}
                                />
                                <button
                                    className="bg-gray p-2 m-2 border-0 "
                                    onClick={handleUpload}
                                >
                                    Upload
                                </button>
                            </div>
                        </div>
                        <div className="mb-3 col-6 ">
                            <label className="form-label">Attach Images <span className="text-danger">*</span></label>
                            <br />
                            <div className="d-flex align-items-center">
                                <input
                                    type="file"
                                    accept=".xlsx, .xls"
                                    name="excel_sheet"
                                    className="form-control"
                                    onChange={handleUpload}
                                />
                                <button
                                    className="bg-gray p-2 m-2 border-0 "
                                    onClick={handleUpload}
                                >   Upload
                                </button>
                            </div>
                        </div>
                        <div className=" d-flex justify-content-center">
                            <button className="btn btn-primary">Add Model</button>
                        </div>
                    </form>
                </div>
            </div>
        </>);
}
export default AddModel;