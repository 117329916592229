import React, { useState } from 'react';
import AddProperty from './AddProperty';
import AddModel from './models/Addmodel';

const Property = () => {
    const [userProperty, setUserProperty] = useState({
        projectName: "",
        propertyType: "",
        propertyArea: "",
        developer: "",
        propClosing: "",
        propClosingYear: 0,
        status: "",
        comission: 0,
        commissionPayment: "",
        developerEmail: "",
        salesRepresentatives: "",
        salesOfficeTelephone: "",
        modelName: "",
        modelCost: "",
        modelSize: 0,
        story: 0,
        frontLotSize: 0,
        lotDepth: 0,
        bedrooms: 0,
        garage: 0,
        bathrooms: 0,
        basement: "",
        basementType: "",
        inclusion: "",
        addOn: "",
        intersection: "",
        projectPhase: 0,
        totalDeposit: 0,
        depositSubmission: "",
        developmentCharges: 0,
        freehold: 0,
        maintainanceAmount: "",
        developerSpecialIncentive: "",
        dhreSpecialIncentive: 0,
        websiteLink: "",
        address: "",
    });
    const [currentPage, setCurrentPage] = useState(1);

    const nextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const prevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    return (
        <div>
            {currentPage === 1 && (
                <div>
                    <AddProperty userProperty={userProperty} setUserProperty={setUserProperty}></AddProperty>
                    <button className="submit btn btn-primary" onClick={nextPage}>Add New Property</button>
                </div>
            )}
            {currentPage === 2 && (
                <div>
                    <AddModel></AddModel>
                    <button className="submit btn btn-primary"  onClick={prevPage}>Previous</button>
                </div>
            )}
        </div>
    );
};

export default Property;