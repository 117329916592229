import BlockUi from 'react-block-ui';
import backgrounImg from '../../images/backgroundImg.jpg';
import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom';
import 'react-block-ui/style.css';

const SessionTimeOut = () => {
    const [blocking, setBlocking] = useState(false);
    const navigate=useNavigate();
return (
    <BlockUi tag="div" blocking={blocking}>
      <div className="background-image">
        <img src={backgrounImg} alt="background-image" />
      </div>
      <div className="container reset_password bg-light p-4">
        <h4 className="text-center fw-bold my-3">SessionTimeOut</h4>
        <div className="text-center">
                    <span>Session is expired. Please relogin</span>  <a className="link" onClick={() => {
                        setBlocking(true);navigate('/login')}}>  Login </a>
        </div>
      </div>
    </BlockUi>
  );
};
export default SessionTimeOut;