// Config.js
const prod = {
    url: {
        //Need to replace with Production servercontext path
        API_URL: 'http://projects.dxmantra.com:8086'
    }
};
const dev = {
    url: {
        API_URL: 'http://projects.dxmantra.com:8086'
       // API_URL: 'http://localhost:8086'
    }
};

export const getContextRoot = () => {
    return process.env.NODE_ENV === 'development' ? dev.url.API_URL : prod.url.API_URL;
}