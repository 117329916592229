import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Accordion, Button, Dropdown, DropdownButton, Container, Row, Col } from 'react-bootstrap';
import { getContextRoot } from '../../../context/Config';

const AddPropertyModel = (props) => {
    const navigate = useNavigate();
    const [accordian,setAccordian] = useState([]);
    const [propertyTypeOptions, setPropertyTypeOptions] = useState([]);

    const location = useLocation();
    const [dataToSave,setDataToSave] = useState([]);

    const [userModel, setUserModel] = useState({
        modelName: "",
        modelCost: "",
        propertyType:"",
        builtUpArea: 0,
        storey: 0,
        frontLotSize: 0,
        lotDepth: 0,
        bedrooms: 0,
        garage: 0,
        bathrooms: 0,
        basement: "",
        basementType: "",
        inclusion: "",
        addOn: "",
        intersection: "",
        projectPhase: 0,
        totalDeposit: 0,
        approval:"NEW",
        depositSubmission: "",
        developmentCharges: 0,
        freehold: "",
        maintainanceAmount: 0,
        developerSpecialIncentive: "",
        dhreSpecialIncentive: 0,
        // reviewComments:""

       
    });
    const [formsData, setFormsData] = useState([]);
    
    const [userModels, setUserModels] = useState([{ userModel }]);
    const [hasFreeHold, setHasFreeHold] = useState("Yes");

    const [frontLotOptions, setfrontLotOptions] = useState([]);
    const [garageOptions, setgarageOptions] = useState([]);
    const [bedRoomsOptions, setbedRoomsOptions] = useState([]);
    const [bathRoomsOptions, setbathRoomsOptions] = useState([]);
    const [basementTypeOptions, setbasementTypeOptions] = useState([]);
    const [rangeValue, setRangeValue] = useState(0);

    const [hasSubmit, setHasSubmit] = useState(false);
    const [hasAdd, setHasAdd] = useState(false);
    const[isSave,setIsSave]=useState(true);
    const[errors,setErrors] = useState([]);
    

    function handleAddModel(e) {
        e.preventDefault();
        setUserModel({ ...userModel, ["maintainanceAmount"]: 0 });
        setHasSubmit(false);
        setIsSave(true);
        // setErrors([...errors])
    }
   
    const handleModelInputChange = (e,index) => {
        const { name, value } = e.target;
        const updatedFormsData = formsData.map((form, i) => i === index ? { ...form, [name]: value } : form);
        //console.log(updatedFormsData);
        setFormsData(updatedFormsData);
    };

    const handleSelectChange=(e,name,index)=>{
        const { value } = e;
        const updatedFormsData = formsData.map((form, i) => i === index ? { ...form, [name]: value } : form);
        setFormsData(updatedFormsData);
    }

    const addAccordian = ()=>{
        const form = userModel;
      setFormsData([...formsData,form]);
    }

    const showAccordians = ()=>{
        return (formsData.map((e,index)=>{
            return ( <Accordion.Item  key={index} eventKey={index}>
                <Accordion.Header>Model {index}</Accordion.Header>
                <Accordion.Body>
                <ModelForm
                            key={index}
                            index={index}
                            userModel={e}
                            handleInputChange={handleModelInputChange}
                            handleSubmit={handleSubmit}
                            handleRangeChange={handleRangeChange}
                            setHasFreeHold={setHasFreeHold}
                            hasFreeHold={e.freehold === 'YES'}
                            bedRoomsOptions={bedRoomsOptions}
                            handleSelectChange = {handleSelectChange}
                            garageOptions={garageOptions}
                            bathRoomsOptions={bathRoomsOptions}
                            basementTypeOptions={basementTypeOptions}
                            price={price} 
                            days={days} 
                            percentage={percentage}
                            rangeValue={rangeValue}
                            isSave= {isSave}
                            hasAdd={hasAdd}
                            handleAddModel={handleAddModel}
                            handleModelCancel={handleModelCancel}
                            hasSubmit={hasSubmit}
                            errors = {errors || []}
                        />
                </Accordion.Body>
              </Accordion.Item>);

        }))
    }

    
    const { handleSubmit } = useForm();//Model form

  
      
    const validateFormData = (formDataArray) => {
        const errorsArray = formDataArray.map((formData, index) => {
          const errors = {};
          let isValid = true;
      
          for (const key in formData) {
            if (formData.hasOwnProperty(key)) {
              const value = formData[key];
              
              // General validation
              if (typeof value === 'number' && value === 0 && key !== 'maintainanceAmount') {
                isValid = false;
                errors[key] = `Field should not be 0`;
              } else if (typeof value === 'string' && !value.trim()) {
                isValid = false;
                errors[key] = `Field should not be empty`;
              }
      
      
              // Special condition: maintainanceAmount can be zero only if freehold is "YES"
              if (key === 'maintainanceAmount' && value === 0 && formData.freehold !== 'YES') {
                isValid = false;
                errors[key] = 'Maintainance amount cannot be zero unless freehold is "YES"';
              }
            }
          }
      
          return { index, isValid, errors };
        });
      
        const overallIsValid = errorsArray.every(item => item.isValid);
        setErrors([...errorsArray])
        return { overallIsValid, errorsArray };
      };
      
      
      
    const onSaveModel = ()=>{
        const { overallIsValid, errorsArray} = validateFormData(formsData); 
        //console.log(overallIsValid, errorsArray)   
        if(!overallIsValid){
                toast.error("All fields are mandatory", {
                position: toast.POSITION.TOP_CENTER,
            });
            return; 
        }
        toast.success("All fields saved, please change the lot size to enter models or click submit to save details", {
            position: toast.POSITION.TOP_CENTER,
        });
        setDataToSave(dataToSave.concat(formsData));
        setFormsData([]);   
        setErrors([...errorsArray]); 

    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if(formsData.length){
            toast.error("Please Click on Save Model before submit", {
                position: toast.POSITION.TOP_CENTER,
            });
            return;
        }

               
        //existing code
        ///userProperty.userPropertyModels=dataToSave;

        let modelsData = {propertyId:"", propertyModels:[]};
        let propertyIdFromProperty = location.state.propertyId.toString();

        modelsData.propertyId = propertyIdFromProperty;
        modelsData.propertyModels = dataToSave;
        
        try {
            //const response = await axios.post("http://localhost:8086/api/public/project-details/create", userProperty);
           // const response = await axios.post("http://localhost:8086/api/public/project-details/createModel", modelsData);

           const response = await axios.post(getContextRoot()+"/api/public/project-details/createModel", modelsData);
           //console.log("Data sent to backend successfully", response.data);

            toast.success("Data sent to backend successfully", {
                position: toast.POSITION.TOP_RIGHT,
            });

            navigate("/property-list");

            // Optionally, you can handle the response from the server here
        } catch (error) {
            toast.error("Error submitting data", {
                position: toast.POSITION.TOP_RIGHT,
            });
            console.error("Error sending data to backend:", error);
        }
    };

    const fetchData = async () => {
        try {
            const [frontLotResponse, garageResponse, bedRoomsResponse, bathRoomsResponse, basementTypeResponse, propertyTypeResponse] = await axios.all([
                /*axios.get("http://localhost:8086/api/metadata/frontlot/all"),
                axios.get("http://localhost:8086/api/metadata/garage/all"),
                axios.get("http://localhost:8086/api/metadata/bedroom/all"),
                axios.get("http://localhost:8086/api/metadata/bathroom/all"),
                axios.get("http://localhost:8086/api/metadata/basement/all"),
                axios.get("http://localhost:8086/api/metadata/property/all"),*/


                axios.get(getContextRoot()+"/api/metadata/frontlot/all"),
                axios.get(getContextRoot()+"/api/metadata/garage/all"),
                axios.get(getContextRoot()+"/api/metadata/bedroom/all"),
                axios.get(getContextRoot()+"/api/metadata/bathroom/all"),
                axios.get(getContextRoot()+"/api/metadata/basement/all"),
                axios.get(getContextRoot()+"/api/metadata/property/all"),

                

                
            ]);

            setfrontLotOptions(frontLotResponse.data.map(item => ({ value: item.frontLot, label: item.frontLot })));
            setgarageOptions(garageResponse.data.map(item => ({ value: item.garage, label: item.garage })));
            setbedRoomsOptions(bedRoomsResponse.data.map(item => ({ value: item.numberOfBedrooms, label: item.numberOfBedrooms })));
            setbathRoomsOptions(bathRoomsResponse.data.map(item => ({ value: item.numberOfBathrooms, label: item.numberOfBathrooms })));
            setbasementTypeOptions(basementTypeResponse.data.map(item => ({ value: item.basementField, label: item.basementField })));
            setPropertyTypeOptions(propertyTypeResponse.data.map(item => ({ value: item.id, label: item.propertyField })));

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleModelCancel = (e) => {
        e.preventDefault();
        let userProperty = location.state;
        navigate("/add-property", { state: { userProperty } });
    }

    //Model details
    const calculateValues = () => {
        const price = rangeValue * 10;
        const days = rangeValue / 10;
        const percentage = rangeValue / 100;
        return { price, days, percentage };
    }

    const handleRangeChange = (e) => {
        setRangeValue(parseInt(e.target.value));
    };

    const { price, days, percentage } = calculateValues();
    useEffect(() => {
        fetchData();
        
    }, [hasSubmit]);
    
    return (
        <>
         <Container className="my-3">
        <Row className="justify-content-center">
        <div className="d-flex  align-items-start">
      <div className="card d-flex justify-content-center" style={{ width: '70%', height: '200px' }}>
        <Col md="auto" className="w-100">
          <div className="d-inline-flex align-items-center w-100">
          <label className="font-weight-bold mr-4" style={{ fontSize: '15px' }}>Please Select Lot Size</label>
            <Select
              options={frontLotOptions}
              className="custom-react-select flex-grow-1"
              placeholder="Lot Size"
              isSearchable={false}
              value={frontLotOptions.find(option => option.value === userModel.frontLotSize)}
              onChange={(selectedOption) =>
                setUserModel({
                  ...userModel,
                  frontLotSize: selectedOption.value,
                })
              }
              required
              styles={{ container: (provided) => ({ ...provided, width: '60%' }) }}
            />
            {/* {!!userModel.frontLotSize && (
              <Button variant="primary" className="ml-3" onClick={addAccordian}>Add Model</Button>
            )} */}
          </div>
        </Col>
      </div>
      
    </div>
        </Row>
      </Container>
      {!!userModel.frontLotSize && <Container className="my-3">
        <Row className="justify-content-center">
        <div className="d-flex  align-items-start">
      <div className="card d-flex justify-content-center" style={{ width: '70%', height: '200px' }}>
        <Col md="auto" className="w-100">
          <div className="d-inline-flex align-items-center w-100">
          <label className="font-weight-bold mr-4" style={{ fontSize: '15px' }}>Please Select Project Type</label>
            <Select
              options={propertyTypeOptions}
              className="custom-react-select flex-grow-1"
              placeholder="Property Type"
              isSearchable={false}
              value={propertyTypeOptions.find(option => option.label === userModel.label)}
              onChange={(selectedOption) =>
                setUserModel({
                  ...userModel,
                  propertyType: selectedOption.label,
                })
              }
              required
              styles={{ container: (provided) => ({ ...provided, width: '60%' }) }}
            />
            {!!userModel.frontLotSize && !! userModel.propertyType && (
              <Button variant="primary" className="ml-3" onClick={addAccordian}>Add Model</Button>
            )}
          </div>
        </Col>
      </div>
      
    </div>
        </Row>
      </Container>}
         <Accordion defaultActiveKey="0">
         
      {showAccordians()}
    
    </Accordion>
    {((!!userModel.frontLotSize && !!formsData.length) || dataToSave && !!dataToSave.length)&& <div className="d-flex justify-content-center">
                               {!!formsData.length && <button className="btn btn-sm btn-primary me-2" name="btnSave" style={{ display: (isSave ? "block" : "none") }} onClick={onSaveModel}> Save Model</button>}
                                {/* <button className="btn btn-sm btn-primary me-2" style={{ display: (true ? "block" : "none") }} onClick={(e) => handleAddModel(e)}>Add Model</button> */}
                               <button className="btn btn-sm btn-primary me-2" name="btnSubmit" disabled={!dataToSave.length} onClick={onSubmit} >Submit</button>
                                <button className="btn btn-sm btn-primary me-2" onClick={handleModelCancel}>Cancel</button>
                            </div>}
            <ToastContainer />
        </>
    );
}

const ModelForm = ({ index, userModel, handleInputChange, handleSubmit, handleRangeChange, setHasFreeHold, hasFreeHold,bedRoomsOptions,handleSelectChange, garageOptions, basementTypeOptions, bathRoomsOptions,price, days, percentage,rangeValue,isSave,hasAdd,handleAddModel,handleModelCancel,hasSubmit,errors })=>{
    const getError =(index, fieldName)=>{
        return (errors && errors.length>0 && errors.length> index && errors[index].errors[fieldName] ? <span className="text-danger">{errors[index].errors[fieldName]}</span> :"")

    }
    
    return <div className="row property">
    <div className="col-12">
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">Add Model</h4>
            </div>
            <div className="card-body">
                <form className="row g-3" onSubmit={(e) => handleSubmit(e, index)}>
                    <div className="row">
                        <div className="mb-3 model-col-6">
                            <label className="form-label">Model Name <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Model Name"
                                name="modelName"
                                value={userModel.modelName}
                                required
                                onChange={(e) => handleInputChange(e, index)}
                            />
                            {getError(index,"modelName")}
                        </div>

                        <div className="mb-3 model-col-6">
                            <label className="form-label">Model Cost <span className="text-danger">*</span></label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Enter Model Cost"
                                name="modelCost"
                                value={userModel.modelCost}
                                onChange={(e) => handleInputChange(e, index)}
                                min="0"
                                required
                            />
                                {getError(index,"modelCost")}
                        </div>

                        <div className="mb-3 model-col-6">
                            <label className="form-label">Build Up Area <span className="text-danger">*</span></label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Enter build up area"
                                name="builtUpArea"
                                value={userModel.builtUpArea}
                                onChange={(e) => handleInputChange(e, index)}
                                required
                            />
                            {getError(index,"builtUpArea")}
                        </div>


                        <div className="mb-3 model-col-6">
                            <label className="form-label">Storey <span className="text-danger">*</span></label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Enter storey"
                                name="storey"
                                value={userModel.storey}
                                onChange={(e) => handleInputChange(e, index)}                                    min="0"
                                required
                            />
                             {getError(index,"storey")}
                        </div>
                        <div className="mb-3 model-col-6">
                            <label className="form-label">Lot Depth <span className="text-danger">*</span></label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Enter Front Lot size"
                                name="lotDepth"
                                value={userModel.lotDepth}
                                min="0"
                                onChange={(e) => handleInputChange(e, index)}                                    required
                            />
                             {getError(index,"lotDepth")}
                        </div>
                        <div className="mb-3 model-col-6">
                            <label className="form-label">Bedrooms <span className="text-danger">*</span></label>
                            <Select
                                options={bedRoomsOptions}
                                className="custom-react-select"
                                placeholder="Enter Bedrooms"
                                isSearchable={false}
                                label="bedrooms"
                                value={bedRoomsOptions.find((option) => option.value === userModel.bedrooms)}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, "bedrooms",index)}
                                required
                            />
                             {getError(index,"bedrooms")}
                        </div>

                        <div className="mb-3 model-col-6">
                            <label className="form-label">Garage <span className="text-danger">*</span></label>
                            <Select
                                options={garageOptions}
                                className="custom-react-select"
                                placeholder="Enter Garage"
                                isSearchable={false}
                                value={garageOptions.find((option) => option.value === userModel.garage)}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, "garage",index)}
                                required
                            />
                            {getError(index,"garage")}
                        </div>
                        <div className="mb-3 model-col-6">
                            <label className="form-label">Bathrooms <span className="text-danger">*</span></label>
                            <Select
                                options={bathRoomsOptions}
                                className="custom-react-select"
                                placeholder="Enter Bathrooms"
                                isSearchable={false}
                                value={bathRoomsOptions.find((option) => option.value === userModel.bathrooms)}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, "bathrooms",index)}
                                required
                            />
                              {getError(index,"bathrooms")}
                        </div>

                        <div className="mb-3 model-col-6">
                            <label className="form-label">Basement <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Basement"
                                name="basement"
                                value={userModel.basement}
                                onChange={(e) => handleInputChange(e, index)}                                    required
                            />
                             {getError(index,"basement")}
                        </div>

                        <div className="mb-3 model-col-6">
                            <label className="form-label">Basement Type <span className="text-danger">*</span></label>
                            <Select
                                options={basementTypeOptions}
                                className="custom-react-select"
                                placeholder="Enter Basement Type"
                                isSearchable={false}
                                value={basementTypeOptions.find((option) => option.value === userModel.basementType)}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, "basementType",index)}
                                required
                            />
                             {getError(index,"basementType")}
                        </div>

                        <div className="mb-3 model-col-6">
                            <label className="form-label">Inclusion <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Inclusion"
                                name="inclusion"
                                value={userModel.inclusion}
                                onChange={(e) => handleInputChange(e, index)}                                    required
                            />
                              {getError(index,"inclusion")}
                        </div>

                        <div className="mb-3 model-col-6">
                            <label className="form-label">Add On <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Add on"
                                name="addOn"
                                value={userModel.addOn}
                                onChange={(e) => handleInputChange(e, index)}                                    required
                            />
                             {getError(index,"addOn")}
                        </div>
                        <div className="mb-3 model-col-6">
                            <label className="form-label">Intersection <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Intersection"
                                name="intersection"
                                value={userModel.intersection}
                                onChange={(e) => handleInputChange(e, index)}                                    required
                            />
                              {getError(index,"intersection")}
                        </div>

                        <div className="mb-3 model-col-6">
                            <label className="form-label">Project Phase <span className="text-danger">*</span></label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Enter Project Phase"
                                name="projectPhase"
                                min="0"
                                value={userModel.projectPhase}
                                onChange={(e) => handleInputChange(e, index)}                                    required
                            />
                  {getError(index,"projectPhase")}

                        </div>

                        <div className="mb-3 model-col-6">
                            <label className="form-label">Total Deposit <span className="text-danger">*</span></label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Eneter Total Deposit"
                                name="totalDeposit"
                                min="0"
                                value={userModel.totalDeposit}
                                onChange={(e) => handleInputChange(e, index)}                                    required
                            />
                              {getError(index,"totalDeposit")}
                        </div>

                        <div className="mb-3 model-col-6">
                            <label className="form-label">Development Charges <span className="text-danger">*</span></label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Enter Development Charges"
                                name="developmentCharges"
                                min="0"
                                value={userModel.developmentCharges}
                                onChange={(e) => handleInputChange(e, index)}                                    required
                            />
                            {getError(index,"developmentCharges")}
                        </div>

                        <div className="mb-3 model-col-6">
                            <label className="form-label">Freehold <span className="text-danger">*</span></label>
                            <label className="form-label freeHold-label">
                                <input type="radio"
                                    className="radio freeHold-radio"
                                    value="YES"
                                    name="freehold"
                                    onClick={() => setHasFreeHold(true)}
                                    onChange={(e) => handleInputChange(e, index)}                                    />
                                Yes
                            </label>
                            <label className="form-label freeHold-label">
                                <input type="radio"
                                    className="radio freeHold-radio"
                                    value="NO"
                                    name="freehold"
                                    onClick={() => setHasFreeHold(false)}
                                    onChange={(e) => handleInputChange(e, index)}                                    />
                                No
                            </label>
                        </div>

                        {!hasFreeHold &&
                            <div className="mb-3 model-col-6">
                                <label className="form-label">Maintainance<span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Maintainance amount"
                                    name="maintainanceAmount"
                                    value={userModel.maintainanceAmount}
                                    onChange={(e) => handleInputChange(e, index)}                                        required
                                />
                                {getError(index,"maintainanceAmount")}
                            </div>
                        }

                        <div className="mb-3 model-col-6">
                            <label className="form-label">
                                Developer Special Incentive
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Developer Incentive"
                                name="developerSpecialIncentive"
                                value={userModel.developerSpecialIncentive}
                                onChange={(e) => handleInputChange(e, index)}                                    
                                required
                            />
                             {getError(index,"developerSpecialIncentive")}
                        </div>

                        <div className="mb-3 model-col-6">
                            <label className="form-label">DHRE Special Incentive <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter DHRE Incentive"
                                name="dhreSpecialIncentive"
                                value={userModel.dhreSpecialIncentive}
                                onChange={(e) => handleInputChange(e, index)}
                               
                                required
                            />
                             {getError(index,"dhreSpecialIncentive")}
                        </div>
                        <div className="mb-3 model-col-6">
                            <label className="form-label">Deposit Submission <span className="text-danger">*</span></label>
                            <textarea
                                type="text"
                                className="form-control"
                                placeholder="Enter Deposit Submission"
                                name="depositSubmission"
                                value={userModel.depositSubmission}
                                rows = {10}
                                onChange={(e) => handleInputChange(e, index)} 
                                required
                            />
                              {getError(index,"depositSubmission")}
                         
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
};

export default AddPropertyModel;
