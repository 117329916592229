import React, { useState, useEffect } from "react";
import { useDropzone } from 'react-dropzone';
import Spinner from 'react-bootstrap/Spinner';
import axios from "axios";
import {useNavigate } from 'react-router-dom';
import { getContextRoot } from '../../../context/Config';


const BulkUploadProperty = () => {
    const [isApiCall, setApiCall] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileName, setFileName] = useState("");

    let navigate = useNavigate();
    const { acceptedFiles, getRootProps, getInputProps, fileRejections } = useDropzone({
        accept: '.xlsx, .xls', maxFiles: 1
        ,
        validator: (file) => {
            getUploadedFilesInfo();
            setFileName(file.name);
            const allowedExtensions = ['.xlsx', '.xls'];
            const fileExtension = '.' + file.name.split('.').pop();
            if (!allowedExtensions.includes(fileExtension)) {
                setErrorMessage(` Only .xlsx and .xls files are allowed.`)
                return {
                    code: 'file-invalid-type',
                    message: 'Only .xlsx and .xls files are allowed.'
                };
            }
            //aaa
            
            let f = checkDuplicateFile(fileName);
            if(f>0){
                setErrorMessage(` Already uploaded `+file.name);
                return {
                    code: 'Duplicate file',
                    message: 'Duplicates files are not allowed.'
                };
            }
            //aaa
            setErrorMessage("");
            return null; // No validation error
        }
    });

//aaa
    const checkDuplicateFile = (val) => {
        let flag = 0;
        
        if (uploadedFiles.includes(val)) {
            flag++;
        }
        /*uploadedFiles.map((obj) => {
          if (obj.uploadedFileName === val) {
            flag++;
          }
        })*/
        return flag;
      }

 
      const  getUploadedFilesInfo=async() =>{
        
        await axios.get(getContextRoot()+"/api/public/project-details/getUploadedXls")
          .then((response) => {
            let files = [] ;
            
            response.data.map((obj) => {
                files.push(obj.uploadedFileName);
            });

            
            setUploadedFiles(files);
          })
          .catch((error) => {
            setErrorMessage("Error fetching uploaded files info");
            console.error("Error fetching uploaded files info:", error);
            
          });
      };
    
      useEffect(() => {
        if (acceptedFiles.length > 0) {
            
            setApiCall(true);
            
            callExcelUpload(acceptedFiles);
        }
    }, [acceptedFiles]);
        
 //aaa     
   
    const callExcelUpload = async (files) => {
        try {
            
            setApiCall(false);
            const formData = new FormData();
            formData.append('file', files[0]);
            
            formData.append('email', JSON.parse(localStorage.getItem('userinfo')).email);
            formData.append('fileName', fileName);
            //await axios.post('http://localhost:8086/api/public/project-details/upload-customers-data', formData);
            await axios.post(getContextRoot()+'/api/public/project-details/upload-customers-data', formData);
            setApiCall(false);
            setErrorMessage("");
            navigate('/property-list')
        } catch (e) {
            
            setErrorMessage("Excel Upload failed with error");
        }

    }

   
    return (
        <>
            {isApiCall ? (
                <Spinner animation="border" style={{ width: '250px', height: '250px', borderWidth: '15px', position: 'fixed', top: '40%', left: '40%' }} />
            ) : (
                <section className="container">
                    <div {...getRootProps()} style={{ border: '50px dashed #abadaf', borderWidth: '3px', borderStyle: 'dashed', borderColor: '#abadaf', width: "100%", height: '300px' }}>
                        <input {...getInputProps()} />
                        <p style={{ textAlign: 'center', marginTop: '5%', fontSize: '22px' }}>Drag 'n' drop Excel files here, or click to select file</p>
                    </div>
                    <aside>
                        <ul className='text-danger'><b>{errorMessage}</b></ul>
                    </aside>
                </section>
            )}
        </>
    );

}
export default BulkUploadProperty;