// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-toast {
    font-size: 18px; /* Increase the font size */
    padding: 20px; /* Increase the padding */
    border-radius: 10px; /* Increase the border radius */
    width: 700px; /* Increase the width */
    background-color: white;
    color: orange;
  }`, "",{"version":3,"sources":["webpack://./src/jsx/propertyApprovalPage.css"],"names":[],"mappings":"AAAA;IACI,eAAe,EAAE,2BAA2B;IAC5C,aAAa,EAAE,yBAAyB;IACxC,mBAAmB,EAAE,+BAA+B;IACpD,YAAY,EAAE,uBAAuB;IACrC,uBAAuB;IACvB,aAAa;EACf","sourcesContent":[".custom-toast {\n    font-size: 18px; /* Increase the font size */\n    padding: 20px; /* Increase the padding */\n    border-radius: 10px; /* Increase the border radius */\n    width: 700px; /* Increase the width */\n    background-color: white;\n    color: orange;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
