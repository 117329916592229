import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import "../../propertyApprovals.css";
import { addCommas } from "../utils";
import { getContextRoot } from '../../../context/Config';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const PropertyApprovals = () => {
  const [propertyData, setPropertyData] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [search, setSearch] = useState({});
  const navigate = useNavigate();
  let rowIndex = 1;

  useEffect(() => {
    getvalues();
    getBanner();
  }, []);

  const getvalues = async () => {
    try {
      const response = await axios.get(
       // "http://localhost:8086/api/public/project-details/search"
       getContextRoot()+"/api/public/project-details/search"
      );
      setPropertyData(response.data);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getBanner = async () => {
    try {
      const response = await axios.get(
        //"http://localhost:8086/api/public/project-details/approval-counts"
        getContextRoot()+"/api/public/project-details/approval-counts"
      );
      setBannerData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateProject = async ({ id, action }) => {
    setOpen(false);
    try {
      const response = await axios.put(
       // `http://localhost:8086/api/public/project-details/${id}?action=${action}`
      getContextRoot()+`/api/public/project-details/${id}?action=${action}`
      );

      getBanner();
      onSubmit();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openModal = (e, type) => {
    const propData =
      type === "property"
        ? propertyData.filter((d) => {
            return d.projectData.entryId === e.target.getAttribute("value");
          })
        : propertyData.filter((d) => {
            return d.id === e.target.getAttribute("value");
          });
    if (propData) {
      navigate("/property-approvals-details", {
        state: { data: propData, type: type },
      });
    }
  };
  const closeModal = () => {
    setOpen(false);
  };
  const onChange = (event) => {
    let change = { ...search, [event.target.name]: event.target.value };

    setSearch(change);
  };
  const getUniqueProjects = (propertyData) => {
    const uniqueProjectsMap = new Map();
    propertyData &&
      propertyData.forEach((property) => {
        if (!uniqueProjectsMap.has(property.projectData.entryId)) {
          uniqueProjectsMap.set(
            property.projectData.entryId,
            property.projectData
          );
        }
      });

    return Array.from(uniqueProjectsMap.values());
  };

  const getProperties = () => {
    rowIndex = 1;
    const properties = getUniqueProjects(propertyData);
    return (
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Project Name</th>
            <th>Area</th>
            <th>Status</th>
            <th>Developer</th>
            <th>Sales Telephone</th>
            <th>Sales Representative</th>
            <th>Status</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {properties.map((data, index) => (
            <tr key={rowIndex++}>
              <td>{rowIndex}</td>
              <td>{data.projectName}</td>
              <td>{data.propertyArea}</td>
              <td>{data.status}</td>
              <td>{data.developer}</td>
              <td>{data.salesOfficeTelephone}</td>
              <td>{data.salesRepresentatives}</td>
              <td>{data.approval}</td>
              <td
                data-entry-id={data.entryId}
                value={data.entryId}
                onClick={(e) => openModal(e, "property")}
                style={{ color: "#007bff", cursor: "pointer" }}
              >
                Details
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const onSubmit = async () => {
    try {
      const response = await axios.get(
        //"http://localhost:8086/api/public/project-details/search",
        getContextRoot()+"/api/public/project-details/search",
        { params: search }
      );
      setPropertyData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  function searchProjects({
    approval,
    modelSize,
    projectName,
    propertyType,
    minBuiltUpArea,
    maxBuiltUpArea,
  }) {
    if (!data) return;

    const filtered = data.filter((item) => {
      const matchesApproval = search.status
        ? item.approval === search.status.toUpperCase()
        : true;
      const matchesModelSize = search.modelSize
        ? item.modelSize === search.modelSize
        : true;
      const matchesProjectName = search.projectName
        ? item.projectData.projectName.toUpperCase().includes(search.projectName.trim().toUpperCase())
        : true;
      const matchesPropertyType = search.propertyType
        ? item.projectData.propertyType.includes(search.propertyType)
        : true;
        const matchesPropertyArea = search.area
        ? item.projectData.propertyArea.includes(search.area)
        : true;

      return (
        matchesApproval &&
        matchesModelSize &&
        matchesProjectName &&
        matchesPropertyType &&
        matchesPropertyArea
      );
    });
    setPropertyData(filtered);
  }

  return (
    <div>
      <div className="row">
        <div className="col-sm-12 col-md-4">
          <div className="card property-card">
            <div className="card-body">
              <div className="media align-items-center">
                <div className="media-body me-3">
                  <p className="property-p mb-0 text-black font-w500">
                    Total No. of Approved Models
                  </p>
                  <h2 className="fs-28 text-black font-w600">
                    {bannerData.trueCount}
                  </h2>
                </div>
                <div className=" ">
                  <i className="flaticon-home" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-4">
          <div className="card property-card">
            <div className="card-body">
              <div className="media align-items-center">
                <div className="media-body me-3">
                  <p className="property-p mb-0 text-black font-w500">
                    Total Number of Rejected Models
                  </p>
                  <h2 className="fs-28 text-black font-w600">
                    {bannerData.falseCount}
                  </h2>
                </div>
                <div className=" ">
                  <i className="flaticon-home" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-4">
          <div className="card property-card">
            <div className="card-body">
              <div className="media align-items-center">
                <div className="media-body me-3">
                  <p className="property-p mb-0 text-black font-w500">
                    Total Projects Older than 30 Days
                  </p>
                  <h2 className="fs-28 text-black font-w600">
                    {bannerData.countBefore30Days}
                  </h2>

                  {/* <span className="fs-13">Target 3k/month</span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row" style={{ "flex-wrap": "nowrap", width: "70%" }}>
          <div className="col-md-3 mb-3">
            <input
              type="text"
              onChange={onChange}
              name="projectName"
              className="form-control"
              placeholder="Project Name"
            />
          </div>
          <div className="col-md-3 mb-3">
            <input
              type="text"
              onChange={onChange}
              name="type"
              className="form-control"
              placeholder="Project Type"
            />
          </div>
          <div className="col-md-3 mb-3">
            <input
              type="text"
              onChange={onChange}
              name="area"
              className="form-control"
              placeholder="Property Area"
            />
          </div>
           
          <div className="col-md-3 mb-3">
            <input
              type="text"
              onChange={onChange}
              name="sqfeet"
              className="form-control"
              placeholder="Square Feet"
            />
          </div>
          <div className="col-md-3 mb-3">
            <select onChange={onChange} name="status" className="form-control">
              <option value="">Select Status</option>
              <option value="New">New</option>
              <option value="Approve">Approve</option>
              <option value="Reject">Reject</option>
            </select>
          </div>
          <div className="col-md-3 mb-3">
            <button
              type="button"
              onClick={searchProjects}
              className="btn btn-primary btn-block"
            >
              Search
            </button>
          </div>
        </div>
      </div>

      <Tabs
        defaultActiveKey="properties"
        id="property-tabs"
        onSelect={() => {
          rowIndex = 1;
        }}
      >
        <Tab eventKey="properties" title="Projects">
          <div className="card property-card">
            <div className="card-body">
              <h6 className="text-left mb-4">Projects</h6>
              {getProperties()}
            </div>
          </div>
        </Tab>
        <Tab eventKey="models" title="Models">
          <div className="card property-card">
            <div className="card-body">
              <h6 className="text-left mb-4">Models</h6>
              <table className="table">
                <thead>
                  <tr>
                    <th>Model Name</th>
                    <th>Size</th>
                    <th>Type</th>
                    <th>Cost</th>
                    <th>Front Lot</th>
                    <th>Bedrooms</th>
                    <th>Bath</th>
                    <th>Sq ft</th>
                    <th>Status</th>
                    <th>Review Comments</th>
                    <th>Storey</th>
                    <th>Lot Size</th>
                    <th>Garage</th>
                    <th>Basement</th>
                    <th>Basement Type</th>

                    <th>Developer</th>


                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {propertyData.map((data) => (
                    <tr key={data.id}>
                      <td>{data.modelName}</td>
                      <td>{data.builtUpArea}</td>
                      <td>{data.propertyType}</td>
                      <td>{addCommas(data.modelCost)}</td>
                      <td>{data.frontLotSize}</td>
                      <td>{data.bedrooms}</td>
                      <td>{data.bathrooms}</td>
                      <td>{data.builtUpArea}</td>
                     
                      <td>{data.approval}</td>
                      <td>{data.reviewComments}</td>
                      <td>{data.storey}</td>
                      <td>{data.frontLotSize}</td>
                      <td>{data.garage}</td>
                      <td>{data.basement}</td>
                      <td>{data.basementType}</td>
                      <td>{data.projectData.developer}</td>
                      

                      <td
                        data-entry-id={data.id}
                        value={data.id}
                        onClick={(e) => openModal(e, "model")}
                        style={{ color: "#007bff", cursor: "pointer" }}
                      >
                        Details
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default PropertyApprovals;
