import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import PageTitle from "../../../../layouts/PageTitle";

const Inbox = () => {
  const [openMailBar, setOpenMailBar] = useState();
  const [data, setData] = useState(
    document.querySelectorAll(".email-right-box .email-list .message")
  );
  const sort = 10;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll(".email-right-box .email-list .message"));
    chackboxFun();
  }, [test]);
  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };
  const chackbox = document.querySelectorAll(".message input");
  const motherChackBox = document.querySelector("#checkbox1");
  
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };
  return (
    <Fragment>
      <PageTitle activeMenu="Inbox" motherMenu="Email" pageContent="Email" />

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className={`col-xl-3 col-xxl-4 email-left-body ${openMailBar ? "active" : " "}`}>
                  <div className="mb-3 mt-4 mt-sm-0">
                    <div className="p-0">
                      <Link
                        to="/email-compose"
                        className="btn btn-primary btn-block"
                      >
                        Compose
                      </Link>
                    </div>
                    <div className="mail-list mt-4 rounded">
                      <Link to="/email-inbox" className="list-group-item active">
                        <i className="fa fa-inbox font-18 align-middle me-2"></i>
                        Inbox
                        <span className="badge badge-white  badge-sm float-end">
                          198
                        </span>
                      </Link>
                      <Link to="/email-inbox" className="list-group-item">
                        <i className="fa fa-paper-plane font-18 align-middle me-2"></i>
                        Sent
                      </Link>
                      <Link to="/email-inbox" className="list-group-item">
                        <i className="fas fa-star font-18 align-middle me-2"></i>
                        Important
                        <span className="badge badge-danger text-white badge-sm float-end">
                          47
                        </span>
                      </Link>
                      <Link to="/email-inbox" className="list-group-item">
                        <i className="mdi mdi-file-document-box font-18 align-middle me-2"></i>
                        Draft
                      </Link>
                      <Link to="/email-inbox" className="list-group-item">
                        <i className="fa fa-trash font-18 align-middle me-2"></i>
                        Trash
                      </Link>
                    </div>
                    <div className="mail-list mt-4 rounded overflow-hidden">
                      <div className="intro-title d-flex justify-content-between my-0">
                        <h5>Categories</h5>                        
                      </div>
                      <Link to="/email-inbox" className="list-group-item">
                        <span className="icon-warning">
                          <i className="fa fa-circle" />
                        </span>
                        Work
                      </Link>
                      <Link to="/email-inbox" className="list-group-item">
                        <span className="icon-primary">
                          <i className="fa fa-circle" />
                        </span>
                        Private
                      </Link>
                      <Link to="/email-inbox" className="list-group-item">
                        <span className="icon-success">
                          <i className="fa fa-circle" aria-hidden="true"></i>
                        </span>
                        Support
                      </Link>
                      <Link to="/email-inbox" className="list-group-item">
                        <span className="icon-dpink">
                          <i className="fa fa-circle" aria-hidden="true"></i>
                        </span>
                        Social
                      </Link>
                    </div>
                  </div>
                </div>
                  <div className="col-xl-9 col-xxl-8">
                    <div className="email-right-box">
                      <div role="toolbar" className="toolbar d-sm-flex d-block align-items-center">
                        <div className="btn-group mb-1 ">
                          <div className="form-check custom-checkbox ">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="checkbox1"
                              onClick={() => chackboxFun("all")}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="checkbox1"
                            ></label>
                          </div>
                        </div>
                        <div className="btn-group mb-1">
                          <button
                            className="btn btn-primary light px-3"
                            type="button"
                          >
                            <i className="ti-reload"></i>
                          </button>
                        </div>
                        <Dropdown className="btn-group mb-1 mx-2">
                          <Dropdown.Toggle
                            aria-expanded="false"
                            data-toggle="dropdown"
                            className="btn btn-primary px-3 light dropdown-toggle ms-1"
                            type="button"
                          >
                            More <span className="caret"></span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu">
                            <Dropdown.Item
                              to="/email-inbox"
                              className="dropdown-item"
                            >
                              Mark as Unread
                            </Dropdown.Item>
                            <Dropdown.Item
                              to="/email-inbox"
                              className="dropdown-item"
                            >
                              Add to Tasks
                            </Dropdown.Item>
                            <Dropdown.Item
                              to="/email-inbox"
                              className="dropdown-item"
                            >
                              Add Star
                            </Dropdown.Item>
                            <Dropdown.Item
                              to="/email-inbox"
                              className="dropdown-item"
                            >
                              Mute
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className={`email-tools-box float-sm-unset float-end ${openMailBar ? "active" : " "}`} onClick={()=>setOpenMailBar(!openMailBar)}>
                          <i className="fa-solid fa-list-ul"/>
                        </div>
                        <form className="form-head style-1 d-none d-sm-block ms-auto mt-sm-0 mt-3">
													<div className="input-group search-area ms-auto w-100 d-inline-flex">
														<input type="text" className="form-control" placeholder="Search here" />
														<span className="input-group-text pe-3">
															<button className="bg-transparent border-0">
																<svg className="ms-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z" fill="#3B4CB8"></path>
																</svg>
															</button>
														</span>
													</div>
												</form>
                      </div>
                      {/** Single Message */}
                      <div className="email-list mt-3">
                        <div className="message">
                          <div>
                            <div className="d-flex message-single">
                              <div className="pl-1 align-self-center">
                                <div className="form-check custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={() => chackboxFun()}
                                    id="checkbox2"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox2"
                                  />
                                </div>
                              </div>
                              <div className="ms-2">
                                <button className="border-0 bg-transparent align-middle p-0">
                                  <i className="fa fa-star" 
                                    onClick={(e)=>e.target.classList.toggle('yellow')}
                                  />
                                </button>
                              </div>
                            </div>
                            <Link to="/email-read" className="col-mail col-mail-2">
                              <div className="subject">
                                Ingredia Nutrisha, A collection of textile samples lay
                                spread out on the table - Samsa was a travelling
                                salesman - and above it there hung a picture
                              </div>
                              <div className="date">11:49 am</div>
                            </Link>
                          </div>
                        </div>
                        <div className="message">
                          <div>
                            <div className="d-flex message-single">
                              <div className="pl-1 align-self-center">
                                <div className="form-check custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={() => chackboxFun()}
                                    id="checkbox3"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox3"
                                  />
                                </div>
                              </div>
                              <div className="ms-2">
                                <button className="border-0 bg-transparent align-middle p-0">
                                  <i className="fa fa-star" 
                                    onClick={(e)=>e.target.classList.toggle('yellow')}
                                  />
                                </button>
                              </div>
                            </div>
                            <Link to="/email-read" className="col-mail col-mail-2">
                              <div className="subject">
                                Almost unorthographic life One day however a small
                                line of blind text by the name of Lorem Ipsum decided
                                to leave for the far World of Grammar.
                              </div>
                              <div className="date">11:49 am</div>
                            </Link>
                          </div>
                        </div>
                        <div className="message">
                          <div>
                            <div className="d-flex message-single">
                              <div className="pl-1 align-self-center">
                                <div className="form-check custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={() => chackboxFun()}
                                    id="checkbox4"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox4"
                                  />
                                </div>
                              </div>
                              <div className="ms-2">
                                <button className="border-0 bg-transparent align-middle p-0">
                                  <i className="fa fa-star" 
                                    onClick={(e)=>e.target.classList.toggle('yellow')}
                                  />
                                </button>
                              </div>
                            </div>
                            <Link to="/email-read" className="col-mail col-mail-2">
                              <div className="subject">
                                Pointing has no control about the blind texts it is an
                                almost unorthographic life One day however a small
                                line of blind text by the name of
                              </div>
                              <div className="date">11:49 am</div>
                            </Link>
                          </div>
                        </div>
                        <div className="message">
                          <div>
                            <div className="d-flex message-single">
                              <div className="pl-1 align-self-center">
                                <div className="form-check custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={() => chackboxFun()}
                                    id="checkbox5"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox5"
                                  />
                                </div>
                              </div>
                              <div className="ms-2">
                                <button className="border-0 bg-transparent align-middle p-0">
                                  <i className="fa fa-star" 
                                    onClick={(e)=>e.target.classList.toggle('yellow')}
                                  />
                                </button>
                              </div>
                            </div>
                            <Link to="/email-read" className="col-mail col-mail-2">
                              <div className="subject">
                                Even the all-powerful Pointing has no control about
                                the blind texts it is an almost unorthographic life
                                One day however a small line of blind text by the name
                                of
                              </div>
                              <div className="date">11:49 am</div>
                            </Link>
                          </div>
                        </div>
                        <div className="message">
                          <div>
                            <div className="d-flex message-single">
                              <div className="pl-1 align-self-center">
                                <div className="form-check custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={() => chackboxFun()}
                                    id="checkbox6"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox6"
                                  />
                                </div>
                              </div>
                              <div className="ms-2">
                                <button className="border-0 bg-transparent align-middle p-0">
                                  <i className="fa fa-star" 
                                    onClick={(e)=>e.target.classList.toggle('yellow')}
                                  />
                                </button>
                              </div>
                            </div>
                            <Link to="/email-read" className="col-mail col-mail-2">
                              <div className="subject">
                                Ingredia Nutrisha, A collection of textile samples lay
                                spread out on the table - Samsa was a travelling
                                salesman - and above it there hung a picture
                              </div>
                              <div className="date">11:49 am</div>
                            </Link>
                          </div>
                        </div>
                        <div className="message">
                          <div>
                            <div className="d-flex message-single">
                              <div className="pl-1 align-self-center">
                                <div className="form-check custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={() => chackboxFun()}
                                    id="checkbox7"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox7"
                                  />
                                </div>
                              </div>
                              <div className="ms-2">
                                <button className="border-0 bg-transparent align-middle p-0">
                                  <i className="fa fa-star" 
                                    onClick={(e)=>e.target.classList.toggle('yellow')}
                                  />
                                </button>
                              </div>
                            </div>
                            <Link to="/email-read" className="col-mail col-mail-2">
                              <div className="subject">
                                Almost unorthographic life One day however a small
                                line of blind text by the name of Lorem Ipsum decided
                                to leave for the far World of Grammar.
                              </div>
                              <div className="date">11:49 am</div>
                            </Link>
                          </div>
                        </div>
                        <div className="message">
                          <div>
                            <div className="d-flex message-single">
                              <div className="pl-1 align-self-center">
                                <div className="form-check custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={() => chackboxFun()}
                                    id="checkbox8"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox8"
                                  />
                                </div>
                              </div>
                              <div className="ms-2">
                                <button className="border-0 bg-transparent align-middle p-0">
                                  <i className="fa fa-star" 
                                    onClick={(e)=>e.target.classList.toggle('yellow')}
                                  />
                                </button>
                              </div>
                            </div>
                            <Link to="/email-read" className="col-mail col-mail-2">
                              <div className="subject">
                                Pointing has no control about the blind texts it is an
                                almost unorthographic life One day however a small
                                line of blind text by the name of
                              </div>
                              <div className="date">11:49 am</div>
                            </Link>
                          </div>
                        </div>
                        <div className="message unread">
                          <div>
                            <div className="d-flex message-single">
                              <div className="pl-1 align-self-center">
                                <div className="form-check custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={() => chackboxFun()}
                                    id="checkbox9"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox9"
                                  />
                                </div>
                              </div>
                              <div className="ms-2">
                                <button className="border-0 bg-transparent align-middle p-0">
                                  <i className="fa fa-star" 
                                    onClick={(e)=>e.target.classList.toggle('yellow')}
                                  />
                                </button>
                              </div>
                            </div>
                            <Link to="/email-read" className="col-mail col-mail-2">
                              <div className="subject">
                                Even the all-powerful Pointing has no control about
                                the blind texts it is an almost unorthographic life
                                One day however a small line of blind text by the name
                                of
                              </div>
                              <div className="date">11:49 am</div>
                            </Link>
                          </div>
                        </div>
                        <div className="message unread">
                          <div>
                            <div className="d-flex message-single">
                              <div className="pl-1 align-self-center">
                                <div className="form-check custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={() => chackboxFun()}
                                    id="checkbox10"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox10"
                                  />
                                </div>
                              </div>
                              <div className="ms-2">
                                <button className="border-0 bg-transparent align-middle p-0">
                                  <i className="fa fa-star" 
                                    onClick={(e)=>e.target.classList.toggle('yellow')}
                                  />
                                </button>
                              </div>
                            </div>
                            <Link to="/email-read" className="col-mail col-mail-2">
                              <div className="subject">
                                Ingredia Nutrisha, A collection of textile samples lay
                                spread out on the table - Samsa was a travelling
                                salesman - and above it there hung a picture
                              </div>
                              <div className="date">11:49 am</div>
                            </Link>
                          </div>
                        </div>
                        <div className="message">
                          <div>
                            <div className="d-flex message-single">
                              <div className="pl-1 align-self-center">
                                <div className="form-check custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={() => chackboxFun()}
                                    id="checkbox11"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox11"
                                  />
                                </div>
                              </div>
                              <div className="ms-2">
                                <button className="border-0 bg-transparent align-middle p-0">
                                  <i className="fa fa-star" 
                                    onClick={(e)=>e.target.classList.toggle('yellow')}
                                  />
                                </button>
                              </div>
                            </div>
                            <Link to="/email-read" className="col-mail col-mail-2">
                              <div className="subject">
                                Almost unorthographic life One day however a small
                                line of blind text by the name of Lorem Ipsum decided
                                to leave for the far World of Grammar.
                              </div>
                              <div className="date">11:49 am</div>
                            </Link>
                          </div>
                        </div>
                        <div className="message">
                          <div>
                            <div className="d-flex message-single">
                              <div className="pl-1 align-self-center">
                                <div className="form-check custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={() => chackboxFun()}
                                    id="checkbox12"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox12"
                                  />
                                </div>
                              </div>
                              <div className="ms-2">
                                <button className="border-0 bg-transparent align-middle p-0">
                                  <i className="fa fa-star" 
                                    onClick={(e)=>e.target.classList.toggle('yellow')}
                                  />
                                </button>
                              </div>
                            </div>
                            <Link to="/email-read" className="col-mail col-mail-2">
                              <div className="subject">
                                Pointing has no control about the blind texts it is an
                                almost unorthographic life One day however a small
                                line of blind text by the name of
                              </div>
                              <div className="date">11:49 am</div>
                            </Link>
                          </div>
                        </div>
                        <div className="message">
                          <div>
                            <div className="d-flex message-single">
                              <div className="pl-1 align-self-center">
                                <div className="form-check custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={() => chackboxFun()}
                                    id="checkbox13"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox13"
                                  />
                                </div>
                              </div>
                              <div className="ms-2">
                                <button className="border-0 bg-transparent align-middle p-0">
                                  <i className="fa fa-star" 
                                    onClick={(e)=>e.target.classList.toggle('yellow')}
                                  />
                                </button>
                              </div>
                            </div>
                            <Link to="/email-read" className="col-mail col-mail-2">
                              <div className="subject">
                                Even the all-powerful Pointing has no control about
                                the blind texts it is an almost unorthographic life
                                One day however a small line of blind text by the name
                                of
                              </div>
                              <div className="date">11:49 am</div>
                            </Link>
                          </div>
                        </div>
                        <div className="message">
                          <div>
                            <div className="d-flex message-single">
                              <div className="pl-1 align-self-center">
                                <div className="form-check custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={() => chackboxFun()}
                                    id="checkbox14"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox14"
                                  />
                                </div>
                              </div>
                              <div className="ms-2">
                                <button className="border-0 bg-transparent align-middle p-0">
                                  <i className="fa fa-star" 
                                    onClick={(e)=>e.target.classList.toggle('yellow')}
                                  />
                                </button>
                              </div>
                            </div>
                            <Link to="/email-read" className="col-mail col-mail-2">
                              <div className="subject">
                                Ingredia Nutrisha, A collection of textile samples lay
                                spread out on the table - Samsa was a travelling
                                salesman - and above it there hung a picture
                              </div>
                              <div className="date">11:49 am</div>
                            </Link>
                          </div>
                        </div>
                        <div className="message unread">
                          <div>
                            <div className="d-flex message-single">
                              <div className="pl-1 align-self-center">
                                <div className="form-check custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={() => chackboxFun()}
                                    id="checkbox15"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox15"
                                  />
                                </div>
                              </div>
                              <div className="ms-2">
                                <button className="border-0 bg-transparent align-middle p-0">
                                  <i className="fa fa-star" 
                                    onClick={(e)=>e.target.classList.toggle('yellow')}
                                  />
                                </button>
                              </div>
                            </div>
                            <Link to="/email-read" className="col-mail col-mail-2">
                              <div className="subject">
                                Almost unorthographic life One day however a small
                                line of blind text by the name of Lorem Ipsum decided
                                to leave for the far World of Grammar.
                              </div>
                              <div className="date">11:49 am</div>
                            </Link>
                          </div>
                        </div>
                        <div className="message">
                          <div>
                            <div className="d-flex message-single">
                              <div className="pl-1 align-self-center">
                                <div className="form-check custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={() => chackboxFun()}
                                    id="checkbox16"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox16"
                                  />
                                </div>
                              </div>
                              <div className="ms-2">
                                <button className="border-0 bg-transparent align-middle p-0">
                                  <i className="fa fa-star" 
                                    onClick={(e)=>e.target.classList.toggle('yellow')}
                                  />
                                </button>
                              </div>
                            </div>
                            <Link to="/email-read" className="col-mail col-mail-2">
                              <div className="subject">
                                Pointing has no control about the blind texts it is an
                                almost unorthographic life One day however a small
                                line of blind text by the name of
                              </div>
                              <div className="date">11:49 am</div>
                            </Link>
                          </div>
                        </div>
                        <div className="message">
                          <div>
                            <div className="d-flex message-single">
                              <div className="pl-1 align-self-center">
                                <div className="form-check custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={() => chackboxFun()}
                                    id="checkbox17"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox17"
                                  />
                                </div>
                              </div>
                              <div className="ms-2">
                                <button className="border-0 bg-transparent align-middle p-0">
                                  <i className="fa fa-star" 
                                    onClick={(e)=>e.target.classList.toggle('yellow')}
                                  />
                                </button>
                              </div>
                            </div>
                            <Link to="/email-read" className="col-mail col-mail-2">
                              <div className="subject">
                                Even the all-powerful Pointing has no control about
                                the blind texts it is an almost unorthographic life
                                One day however a small line of blind text by the name
                                of
                              </div>
                              <div className="date">11:49 am</div>
                            </Link>
                          </div>
                        </div>
                        <div className="message">
                          <div>
                            <div className="d-flex message-single">
                              <div className="pl-1 align-self-center">
                                <div className="form-check custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={() => chackboxFun()}
                                    id="checkbox18"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox18"
                                  />
                                </div>
                              </div>
                              <div className="ms-2">
                                <button className="border-0 bg-transparent align-middle p-0">
                                  <i className="fa fa-star" 
                                    onClick={(e)=>e.target.classList.toggle('yellow')}
                                  />
                                </button>
                              </div>
                            </div>
                            <Link to="/email-read" className="col-mail col-mail-2">
                              <div className="subject">
                                Ingredia Nutrisha, A collection of textile samples lay
                                spread out on the table - Samsa was a travelling
                                salesman - and above it there hung a picture
                              </div>
                              <div className="date">11:49 am</div>
                            </Link>
                          </div>
                        </div>
                        <div className="message">
                          <div>
                            <div className="d-flex message-single">
                              <div className="pl-1 align-self-center">
                                <div className="form-check custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={() => chackboxFun()}
                                    id="checkbox19"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox19"
                                  />
                                </div>
                              </div>
                              <div className="ms-2">
                                <button className="border-0 bg-transparent align-middle p-0">
                                  <i className="fa fa-star" 
                                    onClick={(e)=>e.target.classList.toggle('yellow')}
                                  />
                                </button>
                              </div>
                            </div>
                            <Link to="/email-read" className="col-mail col-mail-2">
                              <div className="subject">
                                Almost unorthographic life One day however a small
                                line of blind text by the name of Lorem Ipsum decided
                                to leave for the far World of Grammar.
                              </div>
                              <div className="date">11:49 am</div>
                            </Link>
                          </div>
                        </div>
                        <div className="message unread">
                          <div>
                            <div className="d-flex message-single">
                              <div className="pl-1 align-self-center">
                                <div className="form-check custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={() => chackboxFun()}
                                    id="checkbox20"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox20"
                                  />
                                </div>
                              </div>
                              <div className="ms-2">
                                <button className="border-0 bg-transparent align-middle p-0">
                                  <i className="fa fa-star" 
                                    onClick={(e)=>e.target.classList.toggle('yellow')}
                                  />
                                </button>
                              </div>
                            </div>
                            <Link to="/email-read" className="col-mail col-mail-2">
                              <div className="subject">
                                Pointing has no control about the blind texts it is an
                                almost unorthographic life One day however a small
                                line of blind text by the name of
                              </div>
                              <div className="date">11:49 am</div>
                            </Link>
                          </div>
                        </div>
                        <div className="message">
                          <div>
                            <div className="d-flex message-single">
                              <div className="pl-1 align-self-center">
                                <div className="form-check custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={() => chackboxFun()}
                                    id="checkbox21"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox21"
                                  />
                                </div>
                              </div>
                              <div className="ms-2">
                                <button className="border-0 bg-transparent align-middle p-0">
                                  <i className="fa fa-star" 
                                    onClick={(e)=>e.target.classList.toggle('yellow')}
                                  />
                                </button>
                              </div>
                            </div>
                            <Link to="/email-read" className="col-mail col-mail-2">
                              <div className="subject">
                                Even the all-powerful Pointing has no control about
                                the blind texts it is an almost unorthographic life
                                One day however a small line of blind text by the name
                                of
                              </div>
                              <div className="date">11:49 am</div>
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-12 pl-3">
                          <nav>
                            <ul className="pagination pagination-gutter pagination-primary pagination-sm no-bg">
                              <li className="page-item page-indicator">
                                <Link
                                  className="page-link"
                                  to="/email-inbox"
                                  onClick={() =>
                                    activePag.current > 0 &&
                                    onClick(activePag.current - 1)
                                  }
                                >
                                  <i className="la la-angle-left"></i>
                                </Link>
                              </li>
                              {paggination.map((number, i) => (
                                <li
                                  key={i}
                                  className={`page-item  ${
                                    activePag.current === i ? "active" : ""
                                  } `}
                                  onClick={() => onClick(i)}
                                >
                                  <Link className="page-link" to="/email-inbox">
                                    {number}
                                  </Link>
                                </li>
                              ))}

                              <li className="page-item page-indicator">
                                <Link
                                  className="page-link"
                                  to="/email-inbox"
                                  onClick={() =>
                                    activePag.current + 1 < paggination.length &&
                                    onClick(activePag.current + 1)
                                  }
                                >
                                  <i className="la la-angle-right"></i>
                                </Link>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Inbox;
