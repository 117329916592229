import axios from 'axios';
//import { store } from '../store/store';
import { getContextRoot } from '../context/Config';

const axiosInstance = axios.create({
    baseURL: getContextRoot(),
});

axiosInstance.interceptors.request.use((config) => {
    const tokenDetailsString = localStorage.getItem("userinfo");

    if (tokenDetailsString) {
      let tokenDetails = JSON.parse(tokenDetailsString);
      let expireDate = new Date(tokenDetails.expireDate);
      expireDate.setMinutes(expireDate.getMinutes() - 1);
      let todaysDate = new Date();

      if (todaysDate > expireDate) {
        window.location.href = '/session_timeout';
      }
    }

    if (config.url?.includes('/private/')) {
        const token = localStorage.getItem('token');
        config.headers.Authorization =  token ? `Bearer ${token}` : '';
    }
    return config;
});

export default axiosInstance;
