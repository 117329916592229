// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row-border {
    border: 1px solid #ccc; /* Add border style */
    border-radius: 5px; /* Add border radius */
    margin-bottom: 15px; /* Add margin bottom to create space between rows */
    padding: 10px; /* Add padding */
  }

  .no-padding td {
    padding: 0 !important;
  }
  
  .custom-rounded {
    border-radius: 25px !important; /* Adjust the value as needed */
  }

  .no-padding,
.no-padding > .container,
.no-padding > .container-fluid {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/jsx/cards.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB,EAAE,qBAAqB;IAC7C,kBAAkB,EAAE,sBAAsB;IAC1C,mBAAmB,EAAE,mDAAmD;IACxE,aAAa,EAAE,gBAAgB;EACjC;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,8BAA8B,EAAE,+BAA+B;EACjE;;EAEA;;;EAGA,UAAU;AACZ","sourcesContent":[".row-border {\n    border: 1px solid #ccc; /* Add border style */\n    border-radius: 5px; /* Add border radius */\n    margin-bottom: 15px; /* Add margin bottom to create space between rows */\n    padding: 10px; /* Add padding */\n  }\n\n  .no-padding td {\n    padding: 0 !important;\n  }\n  \n  .custom-rounded {\n    border-radius: 25px !important; /* Adjust the value as needed */\n  }\n\n  .no-padding,\n.no-padding > .container,\n.no-padding > .container-fluid {\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
