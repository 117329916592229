import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Tab,
  Nav,
  Collapse,
  TabContainer,
  FormControl,
  Form,
  Button,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../cards.css"; // Import CSS file for custom styles
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { addCommas, getBasementList, getStatusList } from "../utils";
import PageTitle from "../../layouts/PageTitle";
import { Link } from "react-router-dom";
import Search from "../Search/Search";
import { calculateMinMaxValues, getPropertyList } from "../utils";
import PropertyShare from "./PropertyShare";

import { getContextRoot } from "../../../context/Config";
import PropertyShareModal from "./PropertyShareModal";

const CompareProperty = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [propertyTypeArr, setPropertyTypeArr] = useState([]);
  const [basementTypeArr, setbasementTypeArr] = useState([]);
  const [statusArr, setStatusArr] = useState([]);
  const [openMenu, setOpenMenu] = useState(true);
  const [openAdvanceSearch, setAdvanceSearch] = useState(false);
  const [propertyData, setPropertyData] = useState([]);
  const [rangeParameters, setRangeParameters] = useState({
    modelCost: [0, 100000000],
    builtUpArea: [0, 1000000],
    frontLotSize: [0, 100],
    sqFt: [0, 10000],
    //bedrooms data type changed to String
    // bedrooms: [0, 50],
    propClosingYear: [0, 2030],
    totalDeposit: [0, 10000000],
  });
  const [filters, setFilters] = useState({
    projectName: "",
    propertyType: "",
    modelCost: 0,
    builtUpArea: 0,
    frontLotSize: 0,
    //Garage datatype is changed to string
    //garage: -1,
    garage: "",
    basementType: "",
    developer: "",
    propertyArea: "",
    basement: "",
    //Bedrooms datatype is changed to string
    //bedrooms: 0,
    bedrooms: "",
    propClosingYear: 0,
    deposit: 0,
  });

  const [sharePropertyId, setSharePropertyId]=useState('');

  const searchProjects = (
    rangeValues,
    textInputs,
    initialRangeValues,
    initialTextInputs,
    selectedItems
  ) => {
    const rangeValuesChanged = Object.keys(rangeValues).some(
      (key) =>
        rangeValues[key][0] !== initialRangeValues[key][0] ||
        rangeValues[key][1] !== initialRangeValues[key][1]
    );
    const textInputsChanged = Object.keys(textInputs).some(
      (key) => textInputs[key] !== initialTextInputs[key]
    );

    // Check if no filters are changed and no inputs provided, then reset to original data
    if (
      !rangeValuesChanged &&
      !textInputsChanged &&
      !selectedItems &&
      Object.values(textInputs).every((value) => value === "")
    ) {
      setProjectData([...propertyData]); // Reset to original propertyData
      return;
    }

    let bedroomsInput = '';
    if (textInputs.bedrooms) {
      bedroomsInput = textInputs.bedrooms.trim();
      bedroomsInput = bedroomsInput.includes("+") ?
        bedroomsInput.slice(0, bedroomsInput.indexOf("+") + 1) : bedroomsInput;
    }

    const filteredData = propertyData
      .map((project) => {
        const filteredModels = project.projectModelData.filter((modelData) => {

          const bedroomsFilter = () => {

            // Return true if garge input is empty
            if (!textInputs.bedrooms) return true;

            // Return false if model bedrooms is null/empty
            if (!modelData.bedrooms) return false;

            // If model bedrooms contains +, then split bedrooms to get numerical value
            const modelBedRooms = modelData.bedrooms.includes("+") ?
              modelData.bedrooms.slice(0, modelData.bedrooms.indexOf("+")) : modelData.bedrooms;

            // Check if bedrooms input ends with + 
            if (bedroomsInput.endsWith("+")) {
              const minBedrooms = parseInt(bedroomsInput.slice(0, -1), 10);
              return modelBedRooms >= minBedrooms;
            } else {
              // Check if bedrooms input is not  a number
              if (isNaN(bedroomsInput)) {
                return modelBedRooms === bedroomsInput;
              } else {
                const exactBedrooms = parseInt(bedroomsInput, 10);
                return modelBedRooms == exactBedrooms;
              }
            }
          };

          const garageFilter = () => {

            // Return true if garge input is empty
            if (!textInputs.garage) return true;

            // Return false if model garage is null/empty
            if (!modelData.garage) return false;

            const garageInput = textInputs.garage.trim();
            // Check if garage input ends with + 
            if (garageInput.endsWith("+")) {
              const minGarage = parseInt(garageInput.slice(0, -1), 10);
              return modelData.garage >= minGarage;
            } else {
              // Check if garage input is not  a number
              if (isNaN(garageInput)) {
                return modelData.garage === garageInput;
              } else {
                const minGarage = parseInt(garageInput, 10);
                return modelData.garage >= minGarage;
              }
            }
          };

          const basementTypeFilter = () => {
            // Return true if basement type input length is empty
            if (textInputs.basementType.length == 0) return true;

            var result = false;

            // Check whether basement type exists, if not return false
            if (modelData.basementType) {
              // iterate and compare basement type value exists with basement type input
              textInputs.basementType.some(
                (selectedType) => {
                  result = (modelData.basementType.toLowerCase() === selectedType.toLowerCase());
                  return result;
                });
            }

            return result;
          };

          const propertyTypeFilter = () => {
            // Return true if property type input length is empty
            if (textInputs.propertyType.length == 0) return true;

            var result = false;

            // Check whether property type exists, if not return false
            if (modelData.propertyType) {
              // iterate and compare property type value exists with property type input
              textInputs.propertyType.some(
                (selectedType) => {
                  result = (modelData.propertyType.toLowerCase() === selectedType.toLowerCase());
                  return result;
                });
            }

            return result;
          };

          return (
            modelData.modelCost >= rangeValues.modelCost[0] &&
            modelData.modelCost <= rangeValues.modelCost[1] &&
            modelData.builtUpArea >= rangeValues.builtUpArea[0] &&
            modelData.builtUpArea <= rangeValues.builtUpArea[1] &&
            modelData.frontLotSize >= rangeValues.frontLotSize[0] &&
            modelData.frontLotSize <= rangeValues.frontLotSize[1] &&
            modelData.builtUpArea >= rangeValues.sqFt[0] &&
            modelData.builtUpArea <= rangeValues.sqFt[1] &&
            modelData.approval === "APPROVE" &&
            project.propClosingYear >= rangeValues.propClosingYear[0] &&
            project.propClosingYear <= rangeValues.propClosingYear[1] &&
            modelData.totalDeposit >= rangeValues.totalDeposit[0] &&
            modelData.totalDeposit <= rangeValues.totalDeposit[1] &&
            bedroomsFilter() &&
            garageFilter() &&
            basementTypeFilter() &&
            propertyTypeFilter()
          );
        });

        return {
          ...project,
          projectModelData: filteredModels,
        };
      })
      .filter(
        (project) => {
          const propertyAreasFilter = () => {
            // Return true if pselectedItems input length is empty
            if (selectedItems.length == 0) return true;

            var result = false;

            // Check whether property type exists, if not return false
            if (project.propertyArea) {
              result = selectedItems.includes(project.propertyArea);
            }

            return result;
          };

          const statusFilter = () => {
            // Return true if status  input length is empty
            if (textInputs.status.length == 0) return true;

            var result = false;

            // Check whether status  exists, if not return false
            if (project.status) {
              // iterate and compare status  value exists with basement type input
              textInputs.status.some(
                (selectedType) => {
                  result = (project.status.toLowerCase() === selectedType.toLowerCase());
                  return result;
                });
            }

            return result;
          };

          return (project.projectModelData.length > 0 &&
            (!textInputs.projectName ||
              (project.projectName &&
                project.projectName
                  .toLowerCase()
                  .includes(textInputs.projectName.toLowerCase()))) &&
            (!textInputs.developer ||
              (project.developer &&
                project.developer
                  .toLowerCase()
                  .includes(textInputs.developer.toLowerCase()))) &&
            propertyAreasFilter() &&
            statusFilter()
          )
        });

    setProjectData([...filteredData]);
  };

  const getQueryParam = (name) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(name);
  };

  const fetchProjectData = async () => {
    try {
      const idParam = getQueryParam("ids");
      const ids = idParam ? idParam.split(",") : [];

      if (ids.length === 0) {
        throw new Error("No IDs provided");
      }

      const responsePromises = ids.map((id) =>
        //axios.get(`http://localhost:8086/api/public/project-details/get/${id}`)
        axios.get(getContextRoot() + `/api/public/project-details/get/${id}`)
      );
      const responses = await Promise.all(responsePromises);
      const projectData = responses.map((response) => response.data);
      setProjectData(projectData);
      setPropertyData(projectData);

      // Extract column names
      if (projectData.length > 0) {
        const firstProperty = projectData[0];
        const columns = Object.keys(firstProperty.projectModelData[0]);
        setColumns(columns);
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  useEffect(() => {
    fetchProjectData();
  }, []);

  useEffect(() => {
    if (propertyData) setRangeParameters(calculateMinMaxValues(propertyData));
    setPropertyTypeArr(getPropertyList(propertyData));
    setbasementTypeArr(getBasementList(propertyData));
    setStatusArr(getStatusList(propertyData));
  }, [propertyData]);

  const getSumOfModals = (arr, key) => {
    return arr.reduce((accumulator, currentValue) => {
      if (currentValue && currentValue.value !== undefined) {
        return accumulator + currentValue[key];
      } else {
        return accumulator;
      }
    }, 0);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };
  const onSubmit = (
    rangeValues,
    textInputs,
    initialRangeValues,
    initialTextInputs,
    selectedItems
  ) => {
    searchProjects(
      rangeValues,
      textInputs,
      initialRangeValues,
      initialTextInputs,
      selectedItems
    );
  };

  function refreshPage() {
    window.location.reload(false);
  }

  const [showModal, setShowModal] = useState(false);

  const handleClick = (e, propertyId) => {
      if (propertyId) {
        setSharePropertyId(propertyId);
      }
      setShowModal(!showModal);
  };

  return (
    <>
      <Container fluid className="no-padding">
        {propertyData && rangeParameters && (
          <Search
            openSearch={openMenu}
            openAdvanceSearch={openAdvanceSearch}
            onClickOpenSearch={() => setOpenMenu(!openMenu)}
            onClickOpenAdvanceSearch={() =>
              setAdvanceSearch(!openAdvanceSearch)
            }
            onChange={onChange}
            onSubmit={onSubmit}
            hideItems={true}
            range={rangeParameters}
            propertyTypeList={propertyTypeArr}
            basementTypeList={basementTypeArr}
            statusList={statusArr}
          />
        )}
        <div className="justify-content-right">
          <button className="btn btn-primary align-self-center " onClick={refreshPage} >Clear Filter</button>
        </div>
        <Row className="justify-content-around">
          {projectData.map((property, index) => (
            
            <Col key={index} xs={6} sm={6} md={6} lg={6} xl={6}>
             
              <Card className="mb-3 custom-rounded">
                <Card.Body>
                <div >
                      
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="#F27424"
                        color="F27424"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-share-2 float-end"
                        onClick={(e) => handleClick(e, property.entryId.toString())}
                      >
                        <circle cx="18" cy="5" r="3" color="#F27424"></circle>
                        <circle cx="6" cy="12" r="3" color="#F27424"></circle>
                        <circle cx="18" cy="19" r="3" color="#F27424"></circle>
                        <line x1="8.59" y1="13.51" x2="15.42" y2="17.49" color="#F27424"></line>
                        <line x1="15.41" y1="6.51" x2="8.59" y2="10.49" color="#F27424"></line>
                      </svg>
                      
                     
              </div>
                  <Card.Title
                    onClick={() =>
                      navigate("/property-view-details", {
                        state: { data: property },
                      })
                    }
                    style={{
                      color: "#ff6746",
                      cursor: "pointer",
                      fontWeight: 900,
                    }}
                    className="text-center"
                  >
                    {property.projectName}
                   

                  </Card.Title>

                  <Card.Text className="text-center">
                    {property.address}
                  </Card.Text>
                  <Table
                    style={{ border: "1px solid rgba(128,128,128,0.5)" }}
                    bordered
                    responsive
                  >
                    <thead>
                      
                      <tr>
                        {/* {columns.map((column, idx) => ( */}
                        <th style={{ textAlign: "center" }}>{"Area"}</th>
                        <th style={{ textAlign: "center" }}>{"Developer"}</th>
                        <th style={{ textAlign: "center" }}>{"Website"}</th>
                        <th style={{ textAlign: "center" }}>{"Status"}</th>
                        <th style={{ textAlign: "center" }}>{"Closing"}</th>

                        {/* ))} */}
                      </tr>
                    </thead>
                    <tbody>
                      <tr key={1}>
                        <td key={1} style={{ textAlign: "center" }}>
                          {property.propertyArea}
                        </td>
                        <td key={1} style={{ textAlign: "center" }}>
                          {property.developer}
                        </td>
                        <td key={1} style={{ textAlign: "center" }}>
                          {property.websiteLink}
                        </td>
                        <td key={1} style={{ textAlign: "center" }}>
                          {property.status}
                        </td>

                        <td key={1} style={{ textAlign: "center" }}>
                          {property.propClosingYear}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <hr />
                  <Table
                    style={{ border: "1px solid rgba(128,128,128,0.5)" }}
                    bordered
                    responsive
                  >
                    <thead>
                      <tr>
                        {/* {columns.map((column, idx) => ( */}
                        <th style={{ textAlign: "center" }}>{"Model"}</th>
                        <th style={{ textAlign: "center" }}>{"Price"}</th>
                        <th style={{ textAlign: "center" }}>{"Size"}</th>
                        <th style={{ textAlign: "center" }}>{"Lot"}</th>
                        <th style={{ textAlign: "center" }}>{"Bed's"}</th>
                        <th style={{ textAlign: "center" }}>{"Bath"}</th>
                        <th style={{ textAlign: "center" }}>{"Basement"}</th>
                        <th style={{ textAlign: "center" }}>{"Garage"}</th>

                        {/* ))} */}
                      </tr>
                    </thead>
                    <tbody>
                      {property.projectModelData.map(
                        (model, idx) =>
                          model.approval === "APPROVE" && (
                            <tr key={idx}>
                              {/* {columns.map((column, idx) => ( */}
                              <td
                                key={idx}
                                style={{
                                  textAlign: "center",
                                  color: "#ff6746",
                                }}
                              >
                                {model.modelName}
                              </td>
                              <td key={idx} style={{ textAlign: "right" }}>
                                {addCommas(model.modelCost)}
                              </td>
                              <td key={idx} style={{ textAlign: "right" }}>
                                {model.builtUpArea}
                              </td>
                              <td key={idx} style={{ textAlign: "right" }}>
                                {model.frontLotSize}
                              </td>
                              <td key={idx} style={{ textAlign: "right" }}>
                                {model.bedrooms}
                              </td>
                              <td key={idx} style={{ textAlign: "right" }}>
                                {model.bathrooms}
                              </td>
                              <td key={idx} style={{ textAlign: "right" }}>
                                {model.basement}
                              </td>
                              <td key={idx} style={{ textAlign: "right" }}>
                                {model.garage}
                              </td>

                              {/* ))} */}
                            </tr>
                          )
                      )}
                    </tbody>
                  </Table>
                  <Table
                    style={{ border: "1px solid rgba(128,128,128,0.5)" }}
                    bordered
                    responsive
                  >
                    <tbody>
                      <tr style={{ textAlign: "center" }}>
                        <td>
                          <strong>Deposit Information</strong>
                        </td>
                        <td>
                          {getSumOfModals(
                            property.projectModelData,
                            "depositSubmission"
                          )}
                        </td>
                      </tr>
                      <tr style={{ textAlign: "center" }}>
                        <td>
                          <strong>Assignment Development Fees</strong>
                        </td>
                        <td>
                          {getSumOfModals(
                            property.projectModelData,
                            "developmentCharges"
                          )}
                        </td>
                      </tr>
                      <tr style={{ textAlign: "center" }}>
                        <td>
                          <strong>Developer Special Incentive</strong>
                        </td>
                        <td>
                          {getSumOfModals(
                            property.projectModelData,
                            "developerSpecialIncentive"
                          )}
                        </td>
                      </tr>
                      
                    </tbody>
                  </Table>
                 
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        {showModal && <PropertyShareModal toggle={showModal} action = {handleClick} propertyId={sharePropertyId} />}
      </Container>
    </>
  );
};

export default CompareProperty;
