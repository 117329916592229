import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { getContextRoot } from "../../../context/Config";



function AddEmailTemplate() {
    const location = useLocation();


    const navigate = useNavigate();
    const [emailTemplate, setEmailTemplate] = useState({
        templateName:"",
        salutation: "",
        subject: "",
        content: "",
        closing: ""
    });

    

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setEmailTemplate({ ...emailTemplate, [name]: value });
    };

    const { handleSubmit } = useForm();

    const onSubmit = async (data) => {

        try {
            const response = await axios.post(getContextRoot() + "/api/metadata/emailContent/add", emailTemplate);
             toast.success("Data sent to backend successfully", {
                position: toast.POSITION.TOP_RIGHT,
            });

            navigate("/email-Content");

            // Optionally, you can handle the response from the server here
        } catch (error) {
            toast.error("Error submitting data,duplicate data", {
                position: toast.POSITION.TOP_RIGHT,
            });
            console.error("Error sending data to backend:", error);
        }
    }


    const handleCancel = (e) => {
        e.preventDefault();
        navigate("/email-content");
    }


    return (

        <>
            <div className="row property">
                <div className="col-12">
                    <div className="card" name="EmailTemplate">
                        <div className="card-header">
                            <h4 className="card-title">Email Template</h4>

                        </div>
                        <div className="card-body">

                            <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div >
                                        <label className="form-label">Template Name<span className="text-danger">*</span></label>
                                        <input
                                            type="text" 
                                            className="form-control"
                                            placeholder="Template name"
                                            name="templateName"
                                            value={emailTemplate.templateName}
                                            onChange={handleInputChange}
                                            required
                                        />

                                    </div>
                                    <div >
                                        <label className="form-label">Subject <span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Subject"
                                            name="subject"
                                            value={emailTemplate.subject}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div >
                                        <label className="form-label">Salutation <span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Salutation"
                                            name="salutation"
                                            value={emailTemplate.salutation}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div >
                                    <label className="form-label">Content <span className="text-danger">*</span></label>
                                        <textarea className="form-control" name="content" rows={10}
                                            value={emailTemplate.content}
                                            onChange={handleInputChange}
                                            required>
                                        </textarea>

                                    </div>
                                    <div >
                                        <label className="form-label">Signature Text <span className="text-danger">*</span></label>
                                        <textarea className="form-control" name="closing" rows={3}
                                            value={emailTemplate.closing}
                                            onChange={handleInputChange}
                                            required>
                                        </textarea>
                                    </div>


                                    <div className=" d-flex justify-content-end mt-3">
                                        <button className="btn btn-sm btn-primary me-2">Save</button>
                                        <button className="btn btn-sm btn-primary me-2" onClick={handleCancel}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>


    );
}
export default AddEmailTemplate;