import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "../../propertyApprovalPage.css";
import { Row, Col, Container } from "react-bootstrap";
import { getContextRoot } from '../../../context/Config';

const config = {
  property: [
    {
      displayName: "Project Area",
      value: "",
      name: "propertyArea",
      type: "text",
    },
    {
      displayName: "Project Name",
      value: "",
      name: "projectName",
      type: "text",
    },
    { displayName: "Developer", value: "", name: "developer", type: "text" },
    {
      displayName: "Project Closing",
      value: "",
      name: "propClosing",
      type: "text",
    },
    {
      displayName: "Project Closing Year",
      value: "",
      name: "propClosingYear",
      type: "number",
    },
    { displayName: "Status", value: "", name: "status", type: "text" },
    {
      displayName: "Commission",
      value: "",
      name: "commission",
      type: "number",
    },
    {
      displayName: "Commission Payment",
      value: "",
      name: "commissionPayment",
      type: "text",
    },
    {
      displayName: "Developer Email",
      value: "",
      name: "developerEmail",
      type: "email",
    },
    {
      displayName: "Sales Representatives",
      value: "",
      name: "salesRepresentatives",
      type: "text",
    },
    { displayName: "Address", value: "", name: "address", type: "text" },
    {
      displayName: "Sales Office Telephone",
      value: "",
      name: "salesOfficeTelephone",
      type: "tel",
    },
    { displayName: "Project Brochure", value: "", name: "brochure", type: "file", isRequired: false },
    { displayName: "Brochure SharePoint", value: "", name: "brochureSharePoint", type: "url", isRequired: false },
    { displayName: "Sitemap", value: "", name: "siteMap", type: "file", isRequired: false },
    { displayName: "Sitemap SharePoint", value: "", name: "siteMapSharePoint", type: "url", isRequired: false },
    { displayName: "Price List", value: "", name: "priceList", type: "file", isRequired: false },
    { displayName: "Price List SharePoint", value: "", name: "priceListSharePoint", type: "url", isRequired: false },
    { displayName: "Layouts", value: "", name: "layouts", type: "file", isRequired: false },
    { displayName: "Layouts SharePoint", value: "", name: "layoutsSharePoint", type: "url", isRequired: false },
    { displayName: "Project Image", value: "", name: "propertyImage", type: "file", isRequired: false },
    { displayName: "Project Image SharePoint", value: "", name: "propertyImageSharePoint", type: "url", isRequired: false },
    {
      displayName: "Website Link",
      value: "",
      name: "websiteLink",
      type: "url",
    },
    {
      displayName: "Notes",
      value: "",
      name: "notes",
      type: "url",
    },



  ],
  model: [
    {
      displayName: "Model Name",
      value: "Sample Model",
      name: "modelName",
      type: "text",
    },
    {
      displayName: "Model Cost",
      value: "500000",
      name: "modelCost",
      type: "number",
    },
    {
      displayName: "Builtup Area",
      value: "2000",
      name: "builtUpArea",
      type: "number",
    },
    { displayName: "Storey", value: "2", name: "storey", type: "number" },
    {
      displayName: "Front Lot Size",
      value: "50",
      name: "frontLotSize",
      type: "number",
    },
    {
      displayName: "Lot Depth",
      value: "100",
      name: "lotDepth",
      type: "number",
    },
    { displayName: "Bedrooms", value: "4", name: "bedrooms", type: "text" },
    {
      displayName: "Bathrooms",
      value: "3.5",
      name: "bathrooms",
      type: "number",
    },
    { displayName: "Garage", value: "2", name: "garage", type: "text" },
    {
      displayName: "Basement",
      value: "Finished",
      name: "basement",
      type: "text",
    },
    {
      displayName: "Basement Type",
      value: "Full",
      name: "basementType",
      type: "text",
    },
    {
      displayName: "Inclusion",
      value: "Standard",
      name: "inclusion",
      type: "text",
    },
    { displayName: "Add On", value: "None", name: "addOn", type: "text" },
    {
      displayName: "Intersection",
      value: "Main St & 1st Ave",
      name: "intersection",
      type: "text",
    },
    {
      displayName: "Project Phase",
      value: "1",
      name: "projectPhase",
      type: "number",
    },
    {
      displayName: "Total Deposit",
      value: "20000",
      name: "totalDeposit",
      type: "number",
    },
    {
      displayName: "Deposit Submission",
      value: "Upon signing",
      name: "depositSubmission",
      type: "text",
    },
    {
      displayName: "Development Charges",
      value: "10000",
      name: "developmentCharges",
      type: "number",
    },
    { displayName: "Freehold", value: "Yes", name: "freehold", type: "text" },
    {
      displayName: "Maintenance Amount",
      value: "150.75",
      name: "maintainanceAmount",
      type: "number",
    },
    {
      displayName: "Developer Special Incentive",
      value: "5% off",
      name: "developerSpecialIncentive",
      type: "text",
    },
    {
      displayName: "DHRE Special Incentive",
      value: "2000",
      name: "dhreSpecialIncentive",
      type: "number",
    },
    {
      displayName: "Review Comments",
      value: "Great project",
      name: "reviewComments",
      type: "textArea",
      style: { height: "100px" },
    },
  ],
};

const PropertyApprovalPage = ({ }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setuserData] = useState(location?.state?.data[0]);
  const [type, setType] = useState(location?.state?.type);

  const [userRole, setUserRole] = useState(
    localStorage.getItem("userinfo") !== null
      ? JSON.parse(localStorage.getItem("userinfo")).role
      : null
  );

  const updateProject = async ({ id, action, type }) => {
    try {
      const response = await axios.put(
        getContextRoot() + `/api/public/project-details/${id}?action=${action}&type=${type}`
      );
      
      handleBack();
    } catch (error) {
      toast.error("Error: It's not working!", {
        className: "custom-toast",
      });
    }
  };



  const onSubmit = (status, type) => {
    if (status === "reject") {
      onUpdate();
    }
    const id = type === "property" ? userData.projectData.entryId : userData.id;
    // const id = userData ? userData.id : null;
    updateProject({ id: id, action: status, type: type });
  };

  const update = (userData) => {
    let currentData = { ...userData };
    let newData = {};
    newData = { ...currentData.projectData };
    delete currentData.projectData;
    newData["projectModelData"] = [currentData];

    const propertyFormData = new FormData();

    if (newData.brochureFileInfo !== "")
      propertyFormData.append('files', newData.brochureFileInfo);
    if (newData.siteMapFileInfo !== "")
      propertyFormData.append('files', newData.siteMapFileInfo);
    if (newData.priceListFileInfo !== "")
      propertyFormData.append('files', newData.priceListFileInfo);
    if (newData.layoutsFileInfo !== "")
      propertyFormData.append('files', newData.layoutsFileInfo);
    if (newData.propertyImageFileInfo !== "")
      propertyFormData.append('files', newData.propertyImageFileInfo);


    const propertyDetailsBlob = new Blob([JSON.stringify(newData)], {
      type: 'application/json'
    });


    propertyFormData.append("propertyDetails", propertyDetailsBlob);


    axios
      .put(
        //`http://localhost:8086/api/public/project-details/update/${newData.entryId}`,
        getContextRoot() + `/api/public/project-details/update/${newData.entryId}`,
        propertyFormData
      )
      .then((response) => {
        console.log("Project updated successfully:", response.data);
   
      })
      .catch((error) => {
        // Handle error
        console.error("Error updating project:", error);
        toast.error("Error: It's not working!", {
          className: "custom-toast",
        });
      });
  };
  const onUpdate = () => {
    update(userData);
  };

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const handleNestedArray = (e) => {
    const { name, value } = e.target;
    console.log({
      ...userData,
      [name]: value,
    });
    setuserData({
      ...userData,
      [name]: value,
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    userData.projectData[name] = value;
    const updatedData = userData;
    setuserData({ ...updatedData });
  };

  const showButton = (buttonType, approval) => {
    switch (buttonType) {
      case "APPROVE":
        return ["SUPER_ADMIN", "ADMIN", "RESEARCH_ANALYST"].includes(
          userRole
        );

      case "REJECT":
        return (
          ["SUPER_ADMIN", "ADMIN", "RESEARCH_ANALYST"].includes(userRole) ||
          (["AGENT", "DATA_ENTRY_USER"].includes(userRole) &&
            approval === "REJECT")
        );
      case "SAVE":
        return [
          "SUPER_ADMIN",
          "ADMIN",
          "AGENT",
          "DATA_ENTRY_USER",
          "RESEARCH_ANALYST",
        ].includes(userRole);
    }
  };

  const isDataEntryUser = () => {
    return [
      "SUPER_ADMIN",
      "ADMIN",
      "AGENT",
      "DATA_ENTRY_USER",
      "RESEARCH_ANALYST",
    ].includes(userRole);
  };

  const handleFileChange = (e) => {

    if (e.target.files) {
      const name = e.target.name;
      const value = e.target.files[0];
      if (value) {
        const fileName = value.name;
        const fileType = fileName.split('.').pop().toLowerCase();
        //setError(name,'');
        if (name === "propertyImage") {
          if (!['jpeg', 'jpg', 'png', 'svg', 'bmp'].includes(fileType)) {
            toast.error("Only 'jpeg','jpg','png','svg','bmp' are allowed", {
              position: toast.POSITION.TOP_RIGHT,
            });

            console.error("Only 'jpeg','jpg','png','svg','bmp' are allowed");
            return;
          }
        }
        else if (!['pdf'].includes(fileType)) {
          toast.error("Only PDF allowed", {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.error("Only PDF allowed");

          return;
        }

        const fileSize = value.size / 1024 / 1024;
        if (fileSize > 10) {
          toast.error("Size should less than 10 MB", {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.error("Size should less than 10 MB");

          return;
        }


        userData.projectData[name] = fileName;
        userData.projectData[name + "FileInfo"] = value;
        const updatedData = userData;
        setuserData({ ...updatedData });
      }
    }
  };


  const showTextBoxesForModels = () => {
    return (
      <div className="row">
        {config["model"].map((e) => (
          <div className="mb-3 col-4">
            <label className="form-label">
              {e.displayName} <span className="text-danger">*</span>
            </label>
            {e.name === "reviewComments" ? (
              <textArea
                type={"e.type"}
                className="form-control"
                placeholder={`Enter ${e.displayName}`}
                name={e.name}
                value={userData[`${e.name}`]}
                onChange={handleNestedArray}
                style={e.style ? e.style : {}}
                disabled={isDataEntryUser() ? false : true}
              />
            ) : (
              <input
                type={"e.type"}
                className="form-control"
                placeholder={`Enter ${e.displayName}`}
                name={e.name}
                value={userData[`${e.name}`]}
                onChange={handleNestedArray}
                style={e.style ? e.style : {}}
                disabled={isDataEntryUser() ? false : true}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  const showTextBoxesForProperty = () => {
    return (
      <div className="row">
        {config["property"].map((e) => (
          <div className="mb-3 col-6">
            <label className="form-label">
              {e.displayName} {e.isRequired === false ? "" : <span className="text-danger">*</span>}

            </label>
            {e.name === "reviewComments" ? (
              <textArea
                type={"e.type"}
                className="form-control"
                placeholder={`Enter ${e.displayName}`}
                name={e.name}
                value={userData.projectData[`${e.name}`]}
                onChange={handleInputChange}
                style={e.style ? e.style : {}}
                disabled={isDataEntryUser() ? false : true}
              />
            ) :
              (e.type === "file" ?
                (
                  <>
                    <a href={getContextRoot() + "/api/public/project-details/download?filePath=" + userData.projectData.entryId + "/" + userData.projectData[e.name]} >{userData.projectData[e.name] ? " :" + userData.projectData[e.name] : ""}</a>
                    <input type={e.type}
                      className="form-control"
                      placeholder={`Enter ${e.displayName}`}
                      name={e.name}
                      id="layouts"
                      onChange={handleFileChange}
                      title="Choose layout file"
                      style={e.style ? e.style : {}}
                      disabled={isDataEntryUser() ? false : true}
                    />
                  </>

                )
                : (
                  <input
                    type={"e.type"}
                    className="form-control"
                    placeholder={`Enter ${e.displayName}`}
                    name={e.name}
                    value={userData.projectData[`${e.name}`]}
                    onChange={handleInputChange}
                    style={e.style ? e.style : {}}
                    disabled={isDataEntryUser() ? false : true}
                  />
                ))}
          </div>
        ))}
      </div>
    );
  };

  const showPage = () => {
    switch (type) {
      case "property":
        return showTextBoxesForProperty();
      case "model":
        return showTextBoxesForModels();
      default:
        return <h3>Error while loading data</h3>;
    }
  };

  return (
    userData && (
      <>
        <ToastContainer />
        <div className="row property">
          <div className="col-12">
            <div className="card">
              <div className="card-header" style={{ justifyContent: 'center' }}>
                <h4 className="card-title">Review {type == "property" ? 'Project' : 'Model'}</h4>
              </div>
              {type === 'model' && <div className="card-header">

                <Container style={{ padding: '0', maxWidth: "100%" }}>
                  <Row style={{ padding: '0.5rem', boxShadow: '2px 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '10px', backgroundColor: 'white' }}>
                    {config["property"].map((e, index) => (
                      <Col md={2} key={index} className="mb-2">
                        {" "}
                        {/* Reduce margin bottom */}
                        <div className="p-2">
                          {" "}
                          {/* Reduce padding */}
                          <strong>{e.displayName}:</strong>{" "}
                          {userData.projectData[e.name] || "N/A"}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Container>
              </div>}
              <div className="card-body">
                <form className="row g-3">
                  {showPage()}
                  {/*  */}
                </form>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row justify-content-center">
              {showButton("APPROVE", userData.approval) && (
                <div className="col-2">
                  <button
                    type="button"
                    onClick={() => onSubmit("approve", type)}
                    disabled={(type === 'model' ? userData.approval === "APPROVE" : userData.projectData.approval === "APPROVE") || (type === 'model' && userData.projectData.approval === 'NEW')}
                    className="btn btn-primary btn-block"
                  >
                    Approve
                  </button>
                </div>
              )}
              {showButton("REJECT", userData.approval) && (
                <div className="col-2">
                  <button
                    type="button"
                    onClick={() => onSubmit("reject", type)}
                    disabled={(type === 'model' ? userData.approval === "REJECT" : userData.projectData.approval === "REJECT")
                      || (type === 'model' && userData.projectData.approval === 'NEW')}
                    className="btn btn-secondary btn-block"
                  >
                    Reject
                  </button>
                </div>
              )}
              {showButton("SAVE", userData.approval) && (
                <div className="col-2">
                  <button
                    type="button"
                    onClick={() => onUpdate()}
                    className="btn btn-secondary btn-block"
                  >
                    Save
                  </button>
                </div>
              )}

              {
                <div className="col-2">
                  <button
                    type="button"
                    onClick={() => handleBack()}
                    className="btn btn-secondary btn-block"
                  >
                    Back
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default PropertyApprovalPage;
