
import React, { useState, useEffect, Suspense } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
import Index from "./jsx";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/style.css";
import ForgetPassword from './jsx/pages/Forgetpassword';
import ResetPassword from './jsx/pages/Resetpassword';
import Redirect from './jsx/pages/Redirect';
import SessionTimeOut from  './jsx/pages/SessionTimeOut';

const Login = React.lazy(() => import('./jsx/pages/Login'));
//const Registration = React.lazy(() => import('./jsx/pages/Registration'));

function App(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation().pathname;

  const EXPIRY_TIME_IN_SECS = 1000 * 60 * 15;

  // function to check for user inactivity and logout
  const checkForInactivity = () => {

    const expiryTime = localStorage.getItem("expiryTime");

    if (expiryTime < Date.now()) {
        localStorage.removeItem("expiryTime");
        localStorage.removeItem("userinfo");

        navigate('/session_timeout');
    } 
  }

  // function to update expiry time to user activity event
  const updateExpireTime = () => { 

    // set expiry time to 10 minutes from current time
    const expiryTime = Date.now() + EXPIRY_TIME_IN_SECS;

    localStorage.setItem("expiryTime", expiryTime);
  }


  // use effect to set interval to check for inactivity
  useEffect(() => {
    //checkForInactivity();
    const interval = setInterval(() => {
      checkForInactivity();
    }, (EXPIRY_TIME_IN_SECS));

    // Clear interval on unmount
    return () => clearInterval(interval);
  }, [])


  // update expiry time based on user activity
  useEffect(() => {
    updateExpireTime();
    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);

    //clean up
    return () => {
      window.removeEventListener("click", updateExpireTime);
      window.removeEventListener("keypress", updateExpireTime);
      window.removeEventListener("scroll", updateExpireTime);
      window.removeEventListener("mousemove", updateExpireTime);
    }
  }, []);

  useEffect(() => {
    checkAutoLogin(dispatch, navigate, location);
  }, []);

  let routeblog = (
    <Routes>
      <Route path='/login' element={<Login />} />
      {/*<Route path='/register' element={<Registration />} />*/}
      <Route path='/redirect' element={<Redirect />} />
      <Route path='/forgetpassword' element={<ForgetPassword />} />
      <Route path='/reset_password' element={<ResetPassword />} />
      <Route path='/session_timeout' element={<SessionTimeOut/>}/>
    </Routes>

  );

  if (props.isAuthenticated) {
    return (
      <>
        <Suspense fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }>
          <Index />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }>
          {routeblog}
          <Index />
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default connect(mapStateToProps)(App);
